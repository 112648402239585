import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { RawMessage, TMediaList, TMediaMessage, ToneToOne } from '../../types/channel';
import { AppRootStore } from '../store';
import _ from 'lodash';

export const initialMediaList = {
    messages: [],
    pageState: '',
    end: false,
};

class ChannelSocketStore {
    root: AppRootStore;
    props: any;

    mediaList: TMediaList = initialMediaList;
    mediaLoading: boolean = true;
    isOpenTagScreen: boolean = false;
    oneToOneUser: ToneToOne = {
        id: 0,
        username: '',
    };

    tagsMessages: {
        messages: RawMessage[];
        pageState: string | null;
        end?: boolean;
    } = {
        messages: [],
        pageState: null,
        end: false,
    };

    constructor(root: AppRootStore) {
        makeAutoObservable(this);
        this.root = root;
    }

    getMessageMedia = (dto: TMediaMessage) => {
        if (this.root.messageStore.slug) {
            this.root.socketStore.socket?.emit('media', dto);
        }
    };

    visible = {
        image: true,
        audio: false,
        video: false,
        document: false,
    };

    setTagsMessages = (data: { messages: RawMessage[]; pageState: string; end: boolean }) => {
        if (!!this.tagsMessages.messages) {
            if (data.pageState !== null && this.tagsMessages.pageState === data.pageState) {
                return;
            } else {
                this.tagsMessages.messages = [
                    ...(this.tagsMessages.messages.reverse().filter(item => item?.id === data.messages[0]?.id)
                        .length === 1
                        ? []
                        : data.messages),
                    ...this.tagsMessages.messages,
                ];
                this.tagsMessages.pageState = data.pageState;
                this.tagsMessages.end = data.end;
            }
        } else {
            runInAction(() => {
                this.tagsMessages = data;
            });
        }
    };

    addMessageTags = (message: RawMessage) => {
        if (this.tagsMessages.messages.length === 0) {
            this.tagsMessages.messages.push(message);
        } else {
            if (_.last(this.tagsMessages.messages)?.id === message.id) {
                runInAction(() => {
                    this.tagsMessages.messages.pop();
                    this.tagsMessages.messages.push(message);
                });
            } else {
                this.tagsMessages.messages.push(message);
            }
        }
    };

    clearTagMessages = () => {
        runInAction(() => {
            this.tagsMessages = {
                messages: [],
                pageState: null,
                end: false,
            };
        });
    };

    setMediaMessageList = (data: TMediaList) => {
        if (this.mediaList?.pageState === data.pageState) {
            return;
        } else {
            this.mediaList.messages = [
                ...this.mediaList.messages,
                ...(this.mediaList.messages.filter(item => item.id === data.messages[0]?.id).length === 1
                    ? []
                    : [...data.messages]),
            ];
            this.mediaList.pageState = data.pageState;
            this.mediaList.end = data.end;
        }
    };

    setMediaLoading = (value: boolean) => {
        this.mediaLoading = value;
    };

    setOneToOne = (data: ToneToOne) => {
        this.oneToOneUser = data;
    };

    setIsOpenTagScreen = (isOpen: boolean) => {
        this.isOpenTagScreen = isOpen;
    };

    toglevisible = (key: keyof typeof this.visible) => {
        for (const value in this.visible) {
            this.visible[value] = false;
        }
        this.visible[key] = true;
    };
}

export default ChannelSocketStore;
