const Colors = {
    White: '#fff',
    Blue: '#178BFB',
    Black: '#000',
    Fiord: '#404d66',
    BaliHai: '#8193ae',
    Heather: '#c0c9d6',
    HotPink: '#ff69b4',
    Chambray: '#394199',
    DoveGray: '#6c6c6c',
    GullGray: '#97a6bc',
    Gray: '#747474',
    Porcelain: '#ebedef',
    RedDamask: '#df653e',
    Red: '#CA0813',
    OuterSpace: '#2f3838',
    GrayChateau: '#9fa6b2',
    CuriousBlue: '#329ed8',
    YellowOrange: '#FCB23A',
    Emerald: '#52CD80',
    LightGreen: '#4DC1A2',
    ExtraLightGreen: '#e5f9f3',
    ActiveHastag: '#D8ECFE',
    LightGray: 'rgba(67, 71, 77, 0.04)',
    ChatBG: '#fff',
    ChatText: '#808080',
    LightGray2: '#EEEEEE',
    LightBlue: '#D0E2FB',
    LightRed: '#F3CCCD',
    ActiveHashtagColor: '#E8EAEC',
    Yellow: '#FFFF00',
    chatOverlay: 'rgba(227,225,225,0.6)',
    transparent: 'transparent',
    FalseColor: '#c9c7c9',
    TrueColor: '#81b0ff',
    DarkGray: '#3d4f63',
    Green: '#7fa88b',
    LightPink: '#FFBCBC',
    LightYellow: '#FAF0AF',
    BlueGreen: '#056676',
    Purple: '#765c99',
    LightOrange: '#F5B17B',
    VeryLightGray: '#EBEBEB',
    HeaderBackground: 'rgba(149, 164, 187, 0.20)',
    DarkGrey: 'rgba(0, 0, 0, 0.7)',
};
export default Colors;
