export const de = {
    welcome_text: 'Willkommen bei ChatXO',
    login: 'Login',
    loginScreen: 'Mit E-mail einloggen',
    create_account: 'Neues Konto erstellen',
    change_language: 'Sprachen',
    english_language: 'Englisch',
    german_language: 'Deutsch',
    french_language: 'Französisch',
    italian_language: 'Italienisch',
    spanish_language: 'Spanisch',
    portuguese_language: 'Portugisisch',
    username: 'eindeutiger Benutzer',
    your_email: 'E-mail-Adresse',
    password: 'Passwort',
    your_password: 'Passwort',
    sign_up: 'Registrieren',
    sign_up_header: 'Neues Konto erstellen',
    sign_up_email: 'Mit E-mail-Adresse registrieren',
    sign_up_header_username: 'eineindeutigen Benutzername eingeben',
    groups: 'Gruppen',
    group: 'Gruppe',
    no_avalible_groups: 'Keine Gruppen verfügbar',
    private_group: 'private Gruppe',
    friends: 'Freunde',
    friend: 'Freund',
    filter: 'Filter',
    add_friends: 'Freund/in hinzufügen',
    add: 'hinzufügen',
    added: 'hinzugefügt',
    add_to_channel: 'Möchtest du {{username}} zur Gruppe {{channelName}} hinzufügen?',
    delete_from_channel: 'Möchtest du {{username}} von der Gruppe {{channelName}} entfernen?',
    invite: 'Einladen',
    discover: 'Entdecken',
    create_channel: 'Gruppe erstellen',
    edit_profile: 'Profil bearbeiten',
    group_name: 'Gruppenname',
    invite_friends: 'Freunde hinzufügen',
    no_avalible_friends: 'keine Freunde verfügbar',
    create: 'Erstellen',
    create_channel_req: 'Gruppenname ist erforderlich',
    edit_channel_name: 'Gruppenname bearbeiten',
    save: 'Sichern',
    select_image: 'Bild auswählen',
    random_color: 'Zufällige Farbe',
    leave_group: 'Gruppe verlassen',
    add_hashtag: 'Hashtag hinzufügen',
    done: 'Fertrig',
    take_photo: 'Foto aufnehmen',
    photo_gallery: 'Foto aus der Mediathek wählen',
    video_gallery: 'Video aus der Mediathek wählen',
    choose_document: 'Dokument wählen',
    audio: 'Audiodatei wählen',
    cancel: 'Abbrechen',
    download: 'Download',
    play: 'Play',
    pause: 'Pause',
    media_player: 'Media Player',
    account: 'Account',
    settings: 'Einstellungen',
    relevance: 'Meine Bewertung',
    in_channels: 'in Gruppen',
    per_user: 'pro User',
    empty_rel_channles: 'Keine Relevanz pro Gruppe',
    empty_rel_users: 'Keine Relevanz pro Freunde',
    no_avalible_channels: 'keine Gruppen verfügbar',
    unfriend: 'Als Freund/in entfernen',
    press_to_change: 'press to change',
    error_message_username_unique: 'Username ist nicht eindeutig',
    error_message_username_req: 'Username wird benötigt',
    error_message_username_min: 'Username sollte mindestens 3 Zeichen haben',
    error_message_username_regex: 'Username darf keine Kombination aus Sonderzeichen und Ziffern sein',
    error_message_email_req: 'Email wird benötigt',
    error_message_email_form: 'ungültiges Email-Format',
    error_message_password_regex:
        'Passwort sollte eine Kombination aus Groß- und Kleinbuchstaben, Ziffern und Sonderzeichen sein',
    error_message_password_min: 'Passwort sollte mindestens 8 Zeichen lang sein',
    error_message_password_req: 'Passwort wird benötigt',
    error_message_groupName_req: 'Das erste Symbol darf kein Leerzeichen sein',
    error_type: 'GIF-Format ist nicht zulässig',
    update_profile: 'Aktualisiere dein Profil',
    update_relevance: 'Urteil aktualisieren',

    image_picker_error: 'ImagePicker Error: ',
    join: 'Beitreten...',
    join_group: 'Gruppe beitreten',
    file_size_error: ' Datei kann nicht geladen werden',
    file_size_error_big: 'Die Datei ist zu groß',
    unknown_error: 'Unbekannter Fehler: ',
    no_discover_channels: 'Keine neuen Gruppen zu entdecken',
    error: 'Error',
    permissions_storage_android: 'ChatXO benötigt Zugriff auf deinen Speicher',
    permissions_storage_write_android_message:
        'Die App benötigt Zugriff auf deinen Dateispeicher, damit du die Datei herunterladen kannst',
    no_permissions: 'Berechtigung nicht erteilt',
    how_to_enable: 'Gehe zu den Einstellungen, um die Dateispeicherung zu aktivieren',
    how_to_enable_microphone: 'Gehe zu den Einstellungen, um das Mikrofon zu aktivieren',
    permissions_storage_read_android_message:
        'Die App benötigt Zugriff auf deine Dateispeicher, damit du die Datei abspielen kannst',
    permissions_microphone_android: 'ChatXO benötigt Zugriff auf dein Mikrofon',
    permissions_microphone_android_message:
        'Die App benötigt Zugriff auf dein Mikrofon, damit du eine Datei aufnehmen kannst',
    type_message: 'Schreibe eine Nachricht...',
    relevance_in: 'Relevanz in',
    delete: 'Löschen',
    report: 'Melden',
    copy: 'Kopieren',
    pinMessage: 'Nachricht pinnen',
    unpinMessage: 'Nachricht nicht mehr pinnen',
    reply: 'Antworten',
    reportMessage: 'Nachricht wurde erfolgreich gemeldet',
    remove_image: 'Bild entfernen',
    ok: 'Ok',
    take_video: 'Video machen',
    max_username_chars: 'Der Benutzername sollte maximal 20 Zeichen lang sein',
    hashtag_already_exists: 'Hashtag existiert bereits',
    press_to_record: 'Drücke und halte, um aufzunehmen',
    signUpSucc_title: 'Konto erfolgreich erstellt',
    signUpSucc_message: 'Danke für deine Registrierung! Dein Konto ist jetzt einsatzbereit.',
    personal_relevance: 'Persönliche Relevanz',
    go_to_profile: 'Gehe zum Profil',
    no_longer_in_group: 'Der User ist nicht mehr in der Gruppe',
    invited: 'Eingeladen',
    groupsNumber: 'Guppennummer',
    or: 'oder',
    scanner: 'Scanner',
    groupsInvite: 'Gruppenpasswort',
    groupsName: 'Gruppenname',
    inviteUsers: 'User einlasen',
    createGroup: 'Neue Gruppe erstellen',
    generateNew: 'Neu erstellen',
    noCode: 'Kein Code',
    groupsPassword: 'Passwort',
    noPassword: 'Kein Passwort',
    editGroup: 'Gruppe bearbeiten',
    blockedUsers: 'Blockierte User',
    no_blocked_users: 'Keine blockierten User',
    block_user_dialog: 'Möchtest du {{username}} in {{channelName}} blockieren?',
    unblock_user_dialog: 'DMöchtest du {{username}} in {{channelName}} nicht mehr blockieren?',
    block_user_button: 'User blockieren',
    unblock_user_button: 'Nicht mehr blockieren',
    blocked_user_anuthorized: 'Du wurdest in der Gruppe blockiert. Bitte wenden Sie sich an den Administrator',
    manageUsers: 'User verwalten',
    deleteGroup: 'Gruppe löschen',
    update: 'Update',
    addParticipant: 'Teilnehmer hinzufügen',
    shareGroup: 'Gruppe teilen',
    complete: 'Vollständig',
    link: 'Link',
    copyLink: 'Kopiere Chat Link',
    share: 'Chat teilen',
    enterName: 'Gib einen Namen ein',
    uploadPhoto: 'Bild hochladen',
    linkCopied: 'Link erfolgreich kopiert',
    msgCopied: 'Nachricht wurde kopiert',
    login_fb: 'Weiter mit Facebook',
    login_google: 'Weiter mit Google',
    login_apple: 'Weiter mit Apple',
    web_err_title: 'Error',
    web_err_msg: 'Fehler beim Laden der Webseite',
    forgot_pass: 'Passwort vergessen',
    forgot_text: 'Passwort vergessen',
    reset_btn: 'Senden',
    success_alert_title: 'Erfolgreich',
    reset_alert_msg: 'Überprüfen Sie Ihre E-Mail, es wird ein neues Passwort geben.',
    error_alert_title: 'Error',
    apple_auth_err_msg: 'Fehler beim Generieren des Tokens',
    or_social_auth: 'oder',
    error_google_auth_play_service: 'Fehler in den Google Play-Diensten',
    error_google_auth_other: 'Error',
    error_fb_auth: 'Error',
    error_fb_auth_get_user: 'Beim Abrufen der Benutzerinformationen ist ein Fehler aufgetreten',
    error_channel_404: 'Dieser Kanal existiert noch nicht',
    error_toast: 'Nicht gefunden',
    option_search: 'Suchen',
    option_groupInfo: 'Gruppeninfo',
    option_reportChat: 'Chat melden',
    option_leaveChat: 'Gruppe verlassen',
    delete_from_friends: 'Möchtest du {{username}} von deinen Freunden entfernen?',
    leave_title: 'Gruppe verlassen',
    leave_text: "Bist du sicher, dass du'{{channelName}}verlassen möchtest'?",
    overlayDesc:
        'Hier können Sie die Mindestrelevanz einstellen, die Benutzer haben müssen, um Ihre Nachrichten lesen zu können',
    judgmentOvelayDrag: 'Zum Ändern ziehen',
    removeFriend: 'entfernen',
    groupInfo: 'Gruppeninformation',
    searchPlaceholder: 'Suchen...',
    sendInvite: 'Sende eine Einladungen an deine Gruppe',
    addFriend: 'Freund/in hinzufügen',
    rmFriend: 'als Freund/in entfernen',
    myJudgment: 'Mein Urteil',
    pollTitle: 'Neue Umfrage',
    pollQuestion: 'Umfrage',
    enterPollQuestion: 'Gib deine Umfrage ein',
    loading: 'Laden',
    poll: 'Umfrage',
    byRelevance: '(nach Relevanz)',
    votes: 'Stimmen',
    noVotes: 'keine Stimmen',
    addOptionsCounter: 'Du kannst {{value}} weitere Optionen hinzufügen',
    addOption: 'Option hinzufügen',
    resultByRelevance: 'Ergebnis nach Relevanz anzeigen',
    resultByNumberOfVotes: 'Ergebnis nach Anzahl der Stimmen anzeigen',
    bigPoll: 'UMFRAGE',
    enterAnswer: 'Antwort eingeben',
    vote: 'Abstimmen',
    voted: 'Abgestimmt',
    errorPollInputs: 'Fülle bitte alle Felder aus',
    byRelevanceTitle: 'Nacg Relevanz',
    byNumberOfVotesTitle: 'nach Anzahl der Stimmen',
    showPoll: 'Zeige Umfrage "{{poll}}"',
    newAdmin: 'neuer Admin',
    newAdminDescription: 'Möchtest du {{name}} als neuen Administrator im "{{channel}}"-Chat festlegen?',
    system_message_admin_changed: '{{name}} ist neuer Admin',
    system_message_user_joined: '{{name}} ist dem Chat beigetreten',
    system_message_user_left: '{{name}} hat den Chat verlassen',
    system_message_user_blocked: '{{name}} wurde im Chat geblockt',
    system_message_user_unblocked: '{{name}} {{name}} wurde im Chat entsperrt',
    system_message_user_was_added: '{{name}} wurde zum Chat binzugefügt',
    type_group: 'Gruppen Typ: {{groupType}}',
    group_is_private: 'Nur der Admin des Chats kann einen neuen Teilnehmer einladen',
    group_is_open: 'Jeder Benutzer des Chats kann einen neuen Teilnehmer einladen',
    join_private_group:
        'Sie versuchen, der privaten Gruppe beizutreten. Bitte wenden Sie sich an den Administrator der Gruppe',
    voted_option_title: 'Für die Option {{name}} gestimmt',
    nobody_voted: 'Niemand hat für die Option gestimmt',
    seconds: 'Sekunden',
    second: 'Sekunde',
    minutes: 'Minuten',
    minute: 'Minute',
    hours: 'Stunden',
    hour: 'Stunde',
    one_member: 'Mitglied',
    many_members: 'Mitglieder',
    country: 'Land',
    city: 'Stadt',
    district: 'Gebiet',
    place: 'Ort',
    byWithProp: 'Nach {{prop}}',
    enabled: 'aktiviert',
    disabled: 'deaktiviert',
    relevanceByLocation: 'Relevance nach Ort: {{type}}',
};
