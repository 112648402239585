import _ from 'lodash';
import { makeAutoObservable, toJS } from 'mobx';
import { AppRootStore } from '../store';
import { TMediaTitle, TVoiceMessage } from '../../types/messageType';
import APIs from '../../api/api';
import { Operation } from '../../utils/Operation';
import { message } from 'antd';

class AudioStore {
    root: AppRootStore;
    visibilityList: TMediaTitle[] = [];
    audioTranscriptData = new Operation<any>({});

    constructor(root: AppRootStore) {
        makeAutoObservable(this);
        this.root = root;
    }

    isPlayAudio: {
        [key: string]: boolean;
    } = {};
    messageId: string = '';

    postAudioTranscript = async (data: TVoiceMessage) => {
        await this.audioTranscriptData.run(() => APIs.message.postAudioTranscript(data, {}));

        if (this.audioTranscriptData.data.success) {
            message.info(this.audioTranscriptData.data.message);
        }
    };

    setIsPlayAudio = (id: string, value: boolean, allAudioPause: () => void) => {
        if (this.messageId !== id) {
            _.forIn(this.isPlayAudio, (value, key) => {
                this.isPlayAudio[key] = false;
            });
            this.isPlayAudio[id] = value;
            allAudioPause();
            this.messageId = id;
        } else {
            this.isPlayAudio[id] = value;
        }
    };

    setVisibilityTextShow = (id: string) => {
        this.visibilityList.push({
            id: id,
            isVisibility: true,
        });

        setTimeout(() => {
            this.setVisibilityTextHide(id);
        }, 10000);
    };

    setVisibilityTextHide = (id: string) => {
        this.visibilityList = this.visibilityList.filter(a => a.id !== id);
    };
}

export default AudioStore;
