import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdImages } from 'react-icons/io';
import { LiaPhotoVideoSolid } from 'react-icons/lia';
import { PiFileAudioFill } from 'react-icons/pi';
import { SlDocs } from 'react-icons/sl';
import Header from '../../../components/Header/Header';
import { Loading } from '../../../components/Loading/Loading';
import Text from '../../../components/Text/Text';
import useRootStore from '../../../hooks/useRootStore';
import styles from './GroupMedia.module.css';
import AudioList from './components/AudioList';
import ImageList from './components/ImageList';
import VideoList from './components/VideoList';
import DocsList from './components/DocsList';
import { toJS } from 'mobx';

const GroupMedia = () => {
    const { t } = useTranslation();
    const { closeModal } = useRootStore().routerStore;
    const { channelData } = useRootStore().channelStore;
    const { slug } = useRootStore().messageStore;
    const { getPreviewData } = useRootStore().usersStore;
    const { toglevisible, visible, getMessageMedia, mediaLoading, mediaList, setMediaLoading } =
        useRootStore().channelSocketStore;
    const { show } = useRootStore().visibleStore;
    console.log('mediaList', toJS(mediaList));

    useEffect(() => {
        if (!mediaList.end) {
            getMessageMedia({
                channelSlug: channelData.slug,
                pageState: mediaList.pageState as never,
                type: 'image',
            });
        }
    }, [mediaList.pageState]);

    const toggleTab = useCallback(
        (key: keyof typeof visible) => {
            setMediaLoading(true);
            toglevisible(key);
            getMessageMedia({
                channelSlug: channelData.slug,
                pageState: mediaList.pageState as never,
                type: key,
            });
        },
        [slug],
    );

    const PreviewImage = (data: any) => {
        getPreviewData(data as any);
        show('previewModal');
    };

    const renderMedies = useCallback(() => {
        switch (true) {
            case visible.image:
                return (
                    <ImageList previewImage={PreviewImage} data={mediaList.messages.filter(e => e.type === 'image')} />
                );
            case visible.video:
                return (
                    <VideoList previewImage={PreviewImage} data={mediaList.messages.filter(e => e.type === 'video')} />
                );
            case visible.audio:
                return <AudioList data={mediaList.messages.filter(e => e.type === 'audio')} />;
            case visible.document:
                return <DocsList data={mediaList.messages.filter(e => e.type === 'document')} />;
            default:
                return (
                    <ImageList previewImage={PreviewImage} data={mediaList.messages.filter(e => e.type === 'image')} />
                );
        }
    }, [mediaList, visible, channelData, slug]);

    return (
        <div>
            <Header
                text={t('group_medias')}
                isPremium={channelData.isPremium}
                color={channelData.mainColor}
                colorPText={channelData.colorHighlight}
                leftIcon="arrowRight"
                onLeftIconPress={() => closeModal('right')}
            />
            <div className={styles.header}>
                <div
                    className={styles.headerItem}
                    style={visible.image ? { backgroundColor: '#ddd' } : {}}
                    onClick={() => toggleTab('image')}
                >
                    <IoMdImages size={20} color={channelData.isPremium ? channelData.colorHighlight : ''} />
                    <Text color={channelData.isPremium ? channelData.colorHighlight : ''} fontSize="12px">
                        {t('image')}
                    </Text>
                </div>
                <div
                    className={styles.headerItem}
                    style={visible.video ? { backgroundColor: '#ddd' } : {}}
                    onClick={() => toggleTab('video')}
                >
                    <LiaPhotoVideoSolid color={channelData.isPremium ? channelData.colorHighlight : ''} size={20} />
                    <Text color={channelData.isPremium ? channelData.colorHighlight : ''} fontSize="12px">
                        {t('video')}
                    </Text>
                </div>
                <div
                    className={styles.headerItem}
                    style={visible.audio ? { backgroundColor: '#ddd' } : {}}
                    onClick={() => toggleTab('audio')}
                >
                    <PiFileAudioFill color={channelData.isPremium ? channelData.colorHighlight : ''} size={20} />
                    <Text color={channelData.isPremium ? channelData.colorHighlight : ''} fontSize="12px">
                        {t('audio_m')}
                    </Text>
                </div>
                <div
                    className={styles.headerItem}
                    style={visible.document ? { backgroundColor: '#ddd' } : {}}
                    onClick={() => toggleTab('document')}
                >
                    <SlDocs color={channelData.isPremium ? channelData.colorHighlight : ''} size={20} />
                    <Text color={channelData.isPremium ? channelData.colorHighlight : ''} fontSize="12px">
                        {t('docs')}
                    </Text>
                </div>
            </div>
            <div className={styles.wrap}>
                {mediaLoading && (
                    <>
                        <Loading />
                    </>
                )}
                {renderMedies()}
            </div>
        </div>
    );
};

export default observer(GroupMedia);
