export const fr = {
    welcome_text: 'Welcome to ChatXO',
    login: 'Login',
    loginScreen: 'Login with Email',
    create_account: 'Create an account',
    change_language: 'Langues',
    english_language: 'English',
    german_language: 'Allemand',
    french_language: 'Français',
    italian_language: 'Italien',
    spanish_language: 'Spanish',
    portuguese_language: 'portugais',
    username: "Nom d'utilisateur unique",
    your_email: 'Votre e-mail',
    password: 'Mot de passe',
    your_password: 'Your Password',
    sign_up: 'Sign Up',
    sign_up_header: 'Create an account',
    sign_up_email: 'Regístrese con el correo electrónico',
    sign_up_header_username: 'Introduzca un nombre de usuario único',
    groups: 'Groupes',
    group: 'groupe',
    no_avalible_groups: 'Aucun groupe disponible',
    private_group: 'Groupe privé',
    friends: 'Friends',
    friend: 'friend',
    filter: 'Filter',
    add_friends: 'Ajouter des amis',
    add: 'Ajouter',
    added: 'Added',
    add_to_channel: "Voulez-vous ajouter {{nom_d'utilisateur}} à {{nom_du_canal}} ?",
    delete_from_channel: "Voulez-vous supprimer {{nom_d'utilisateur}} de {{nom_du_canal}} ?",
    invite: 'Invite',
    discover: 'Découvrir',
    create_channel: 'Créer un groupe',
    edit_profile: 'Editer le profil',
    group_name: 'Nom du groupe',
    invite_friends: 'Inviter des amis',
    no_avalible_friends: 'Aucun ami disponible',
    create: 'Create',
    create_channel_req: 'Le nom du groupe est requis',
    edit_channel_name: 'Modifier le nom du groupe',
    save: 'Save',
    select_image: "Sélectionner l'image",
    random_color: 'Couleur aléatoire',
    leave_group: 'Leave group',
    add_hashtag: 'Ajouter un hashtag',
    done: 'Done',
    take_photo: 'Prendre une photo',
    photo_gallery: 'Choisir une photo dans la galerie',
    video_gallery: 'Choisir une vidéo dans la galerie',
    choose_document: 'Choisir un document',
    audio: "Choisir l'audio",
    cancel: 'Annuler',
    download: 'Télécharger',
    play: 'Play',
    pause: 'Pause',
    media_player: 'Media Player',
    account: 'Compte',
    settings: 'Paramètres',
    relevance: 'Mon jugement',
    in_channels: 'dans les groupes',
    per_user: 'par utilisateur',
    empty_rel_channles: 'Aucune pertinence par groupes',
    empty_rel_users: 'Aucune pertinence par amis',
    no_avalible_channels: 'Aucun groupe disponible',
    unfriend: 'Unfriend',
    press_to_change: 'Press to change',
    error_message_username_unique: 'Username is not unique',
    error_message_username_req: "Le nom d'utilisateur est requis",
    error_message_username_min: "Le nom d'utilisateur doit comporter au moins 3 caractères",
    error_message_username_regex: 'Username cannot be a combination of special characters and digits',
    error_message_email_req: 'Email is required',
    error_message_email_form: 'Invalid email format',
    error_message_password_regex:
        'Le mot de passe doit être une combinaison de lettres majuscules et minuscules, de chiffres et de caractères spéciaux',
    error_message_password_min: 'Le mot de passe doit comporter au moins 8 caractères',
    error_message_password_req: 'Le mot de passe est requis',
    error_message_groupName_req: 'Le premier symbole ne peut pas être un espace',
    error_type: "Le format GIF n'est pas autorisé",
    update_profile: 'Mettre à jour votre profil',
    update_relevance: 'Mettre à jour le jugement',
    image_picker_error: "Erreur du sélecteur d'images: ",
    join: 'Rejoindre...',
    join_group: 'Rejoindre le groupe',
    file_size_error: 'Ce fichier ne peut pas être chargé',
    file_size_error_big: 'Le fichier est trop gros',
    unknown_error: 'Unknown Error: ',
    no_discover_channels: 'Aucun nouveau groupe à découvrir',
    error: 'Error',
    permissions_storage_android: "ChatXO a besoin d'accéder à votre stockage",
    permissions_storage_write_android_message:
        "L'application a besoin d'accéder à votre stockage de fichiers pour que vous puissiez télécharger le fichier",
    no_permissions: 'Permission not granded',
    how_to_enable: 'Allez dans les paramètres pour activer le stockage de fichiers',
    how_to_enable_microphone: 'Aller aux paramètres pour activer le microphone',
    permissions_storage_read_android_message:
        "L'application a besoin d'accéder à votre stockage de fichiers pour pouvoir lire le fichier",
    permissions_microphone_android: "ChatXO a besoin d'accéder à votre microphone",
    permissions_microphone_android_message:
        "L'application a besoin d'accéder à votre microphone pour pouvoir enregistrer un fichier",
    type_message: 'Tapez un message...',
    relevance_in: 'Relevance in',
    delete: 'Delete',
    report: 'Rapport',
    copy: 'Copy',
    pinMessage: 'Pin message',
    unpinMessage: 'Unpin message',
    reply: 'Reply',
    reportMessage: 'Le message a été signalé avec succès',
    remove_image: "Supprimer l'image",
    ok: 'Ok',
    take_video: 'Prendre une vidéo',
    max_username_chars: "Le nom d'utilisateur doit comporter 20 caractères maximum",
    hashtag_already_exists: 'Le hashtag existe déjà',
    press_to_record: 'Appuyer et maintenir pour enregistrer',
    signUpSucc_title: 'Account successfully created',
    signUpSucc_message: 'Merci pour votre inscription ! Votre compte est maintenant prêt à être utilisé. ',
    personal_relevance: 'Pertinence personnelle',
    go_to_profile: 'Aller au profil',
    no_longer_in_group: "L'utilisateur n'est plus dans le groupe",
    invited: 'Invited',
    groupsNumber: 'Numéro du groupe',
    or: 'or',
    scanner: 'Scanner',
    groupsInvite: 'Mot de passe du groupe',
    groupsName: 'Nom du groupe',
    inviteUsers: 'Inviter des utilisateurs',
    createGroup: 'Créer un nouveau groupe',
    generateNew: 'Generate New',
    noCode: 'No Code',
    groupsPassword: 'Password',
    noPassword: 'No Password',
    editGroup: 'Editer le groupe',
    blockedUsers: 'Utilisateurs bloqués',
    no_blocked_users: 'Aucun utilisateur bloqué',
    block_user_dialog: 'Voulez-vous bloquer {{nom_utilisateur}} dans {{nom_du_canal}} ?',
    unblock_user_dialog: "Voulez-vous débloquer {{nom_d'utilisateur}} sur {{nom_du_canal}} ?",
    block_user_button: 'Block user',
    unblock_user_button: 'Unblock',
    blocked_user_anuthorized: "Vous avez été bloqué dans le groupe. Veuillez contacter l'administrateur",
    manageUsers: 'Gérer les utilisateurs',
    deleteGroup: 'Supprimer le groupe',
    update: 'Update',
    addParticipant: 'Ajouter un participant',
    shareGroup: 'Partager votre groupe',
    complete: 'Complete',
    link: 'Link',
    copyLink: 'Copier le lien du chat',
    share: 'Partager le chat',
    enterName: 'Entrez un nom',
    uploadPhoto: 'Télécharger une photo',
    linkCopied: 'Lien copié avec succès',
    msgCopied: 'Le message a été copié',
    login_fb: 'Continuer avec Facebook',
    login_google: 'Continuer avec Google',
    login_apple: 'Continuer avec Apple',
    web_err_title: 'Erreur',
    web_err_msg: 'Erreur lors du chargement de la page Web',
    forgot_pass: 'Mot de passe oublié',
    forgot_text: 'Mot de passe oublié',
    reset_btn: 'Send',
    success_alert_title: 'Success',
    reset_alert_msg: 'Vérifiez votre email, il y aura un nouveau mot de passe',
    error_alert_title: 'Error',
    apple_auth_err_msg: 'Erreur lors de la génération du jeton',
    or_social_auth: 'or',
    error_google_auth_play_service: 'Erreur dans les services google play',
    error_google_auth_other: 'Erreur',
    error_fb_auth: 'Erreur',
    error_fb_auth_get_user: "Une erreur s'est produite lors de l'obtention d'informations sur l'utilisateur",
    error_channel_404: "Ce canal n'existe pas encore",
    error_toast: 'Non trouvé',
    option_search: 'Recherche',
    option_groupInfo: 'Infos groupe',
    option_reportChat: 'Rapport de chat',
    option_leaveChat: 'Quitter le groupe',
    delete_from_friends: 'Voulez-vous supprimer {{username}} de vos amis ?',
    leave_title: 'Quitter le groupe',
    leave_text: "Etes-vous sûr de vouloir quitter '{{channelName}}' ?",
    overlayDesc:
        'Vous pouvez définir ici la pertinence minimale que les utilisateurs doivent avoir pour pouvoir lire vos messages',
    judgmentOvelayDrag: 'Drag to change',
    removeFriend: 'supprimé',
    groupInfo: 'Informations sur le groupe',
    searchPlaceholder: 'Rechercher...',
    sendInvite: 'Envoyer des invitations à votre groupe',
    addFriend: 'add friend',
    rmFriend: 'unfriend',
    myJudgment: 'Mon jugement',
    pollTitle: 'Nouveau sondage',
    pollQuestion: 'Question du sondage',
    enterPollQuestion: 'Entrez la question du sondage',
    loading: 'Loading',
    poll: 'Poll',
    byRelevance: '(par pertinence)',
    votes: 'votes',
    noVotes: 'aucun vote',
    addOptionsCounter: "Vous pouvez ajouter {{value}} plus d'options",
    addOption: 'Ajouter une option',
    resultByRelevance: 'Afficher le résultat par pertinence',
    resultByNumberOfVotes: 'Afficher le résultat par nombre de votes',
    bigPoll: 'POLL',
    enterAnswer: 'Enter answer',
    vote: 'Vote',
    voted: 'Voted',
    errorPollInputs: 'Veuillez remplir tous les champs',
    byRelevanceTitle: 'By relevance',
    byNumberOfVotesTitle: 'Par nombre de votes',
    showPoll: 'Afficher le sondage "{{poll}}"',
    newAdmin: 'New Admin',
    newAdminDescription: 'Voulez-vous désigner {{nom}} comme nouvel administrateur dans le chat "{{canal}}" ?',
    system_message_admin_changed: '{{nom}} est un nouvel administrateur',
    system_message_user_joined: '{{nom}} a rejoint le chat',
    system_message_user_left: '{{name}} a quitté le chat',
    system_message_user_blocked: '{{name}} a été bloqué dans le chat',
    system_message_user_unblocked: '{{nom}} a été débloqué dans le chat',
    system_message_user_was_added: '{{nom}} a été ajouté au chat',
    type_group: "Group's Type: {{groupType}}",
    group_is_private: "Seul l'administrateur du chat peut inviter un nouveau participant",
    group_is_open: 'Tout utilisateur du chat peut inviter un nouveau participant',
    join_private_group: "Vous essayez de rejoindre le groupe privé. Veuillez contacter l'administrateur du groupe",
    voted_option_title: "Vous avez voté pour l'option {{nom}}",
    nobody_voted: "Personne n'a voté pour l'option",
    seconds: 'secondes',
    second: 'seconde',
    minutes: 'minutes',
    minute: 'minute',
    hours: 'hours',
    hour: 'hour',
    one_member: 'membre',
    many_members: 'members',
    country: 'pays',
    city: 'ville',
    district: 'district',
    place: 'lieu',
    byWithProp: 'Par {{prop}}',
    enabled: 'activé',
    disabled: 'désactivé',
    relevanceByLocation: 'Pertinence par lieu: {{type}}',
};
