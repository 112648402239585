import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import Header from '../../../components/Header/Header';
import MenuItem from '../../../components/MenuItem/MenuItem';
import SearchInput from '../../../components/SearchInput/SearchInput';
import SmallAvatar from '../../../components/SmallAvatar/smallAvatar';
import Text from '../../../components/Text/Text';
import { TMP_URL } from '../../../env';
import useRootStore from '../../../hooks/useRootStore';
import styles from './ChannelSetting.module.css';
import STR_N from '../../../utils/StringLength';
import { MdAdminPanelSettings, MdOutlineAdminPanelSettings } from 'react-icons/md';
import { useCallback, useEffect } from 'react';
import { toJS } from 'mobx';
import { ChannelsUsersType } from '../../../types/channel';

const ChannelSetting = () => {
    const { t } = useTranslation();

    const {
        adminId,
        getOneMember,
        channelUsers,
        setSearchChannelUsers,
        delateUserFromChannel,
        selectedChannelData,
        channelData,
        getChannelUsers,
        hashId,
    } = useRootStore().channelStore;
    const { createFriend } = useRootStore().friendsStore;
    const { getFriendDetails, isLoading, friendDetails } = useRootStore().usersStore;
    const { user } = useRootStore().authStore;
    const { closeModal, toRouterManageCh } = useRootStore().routerStore;

    useEffect(() => {
        getChannelUsers(hashId);
    }, []);

    const getUser = (id: number) => {
        if (id !== user.id) {
            getOneMember(id);
        }
    };

    const FriendDetails = async (friendId: number) => {
        if (user.id !== friendId && !isLoading && friendDetails.id !== friendId) {
            await getFriendDetails(friendId);
            toRouterManageCh('channelInUser');
        }
    };
    const renderAdminIcon = useCallback(
        (user: ChannelsUsersType) => {
            switch (true) {
                case channelData.adminId === user?.id:
                    return <MdAdminPanelSettings />;
                case user?.isAdmin:
                    return <MdOutlineAdminPanelSettings />;
                default:
                    return null;
            }
        },
        [channelUsers, user],
    );

    return (
        <div
            style={{
                backgroundColor: '#fff',
                width: '100%',
                height: '100vh',
                overflowY: 'scroll',
            }}
        >
            <Header
                text={`${t('members')}`}
                leftIcon="arrowRight"
                onLeftIconPress={() => closeModal('right')}
                color={channelData.mainColor}
                isPremium={channelData.isPremium}
                colorPText={channelData.colorHighlight}
            />
            <div className={styles.searchBox}>
                <SearchInput
                    onChange={e => {
                        setSearchChannelUsers(e);
                    }}
                    placeholder={`${t('searchPlaceholder')}`}
                />
            </div>
            <div className={styles.membersBox}>
                {channelUsers.map((e, index) => {
                    return (
                        <div key={index}>
                            <MenuItem
                                isAdmin={adminId === user.id && adminId !== e.id}
                                onDelete={() => {
                                    delateUserFromChannel(selectedChannelData.hashId, e.id);
                                }}
                                icon={
                                    <SmallAvatar
                                        imageUrl={e.avatar ? `${TMP_URL}/${e.avatar}` : ''}
                                        color={e.color ? e.color : 'linear-gradient(#ddd, #666)'}
                                    />
                                }
                                title={STR_N.userNameLeng(e.username, 16)}
                                right={
                                    <div className={styles.userRelevanceBox}>
                                        <Text children={e.relevance} handleLink={() => getUser(e.id)} />
                                        {renderAdminIcon(e as never)}
                                        {e.isFriend || user.id === e.id ? null : (
                                            <span onClick={() => createFriend(e.id)}>
                                                <BiPlus
                                                    size={24}
                                                    style={{
                                                        padding: '2px',
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </div>
                                }
                                onTitlePress={() => FriendDetails(e.id)}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default observer(ChannelSetting);
