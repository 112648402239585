import React from 'react';
import styles from './BrandGroupLogo.module.css';
import Header from '../../../components/Header/Header';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import useRootStore from '../../../hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import MenuItem from '../../../components/MenuItem/MenuItem';
import ToolbarIcon from '../../../components/ToolbarIcon/ToolbarIcon';
import { FiUpload } from 'react-icons/fi';
import { IoLogoTableau } from 'react-icons/io5';
import { toJS } from 'mobx';
import { TMP_URL } from '../../../env';
import { ButtonComponent } from '../../../utils/button';
import MessageBox from '../../../components/MessageBox/MessageBox';

const BrandGroupLogoAndBack = () => {
    const { t } = useTranslation();
    const { closeModal } = useRootStore().routerStore;
    const { user } = useRootStore().authStore;
    const { updataBrandChannelState, brandLogo, readFile, setUpdateBrandChannelState, channelData } =
        useRootStore().channelStore;

    const isAdmin = channelData.adminId === user.id;

    const handleBack = () => {
        if (brandLogo) {
            setUpdateBrandChannelState('logo', channelData.logo);
        } else {
            setUpdateBrandChannelState('background', channelData.background);
        }
        closeModal('right');
    };

    const uploadFile = (e: File, key: string) => {
        readFile(e, key, 'edit');
    };

    const handleSave = () => {
        closeModal('right');
    };

    return (
        <>
            <Header
                text={`${t('brandGroup')}`}
                textWeight={600}
                leftIcon="arrowLeft"
                onLeftIconPress={handleBack}
                color={channelData.mainColor}
                isPremium={channelData.isPremium}
                colorPText={channelData.colorHighlight}
            />
            <div className={styles.settings}>
                <div className={styles.imageBox}>
                    {brandLogo ? (
                        <img
                            className={styles.image}
                            src={updataBrandChannelState.logo ? `${TMP_URL}/${updataBrandChannelState.logo}` : ''}
                            alt=""
                        />
                    ) : (
                        <img
                            className={styles.image}
                            src={
                                updataBrandChannelState.background
                                    ? `${TMP_URL}/${updataBrandChannelState.background}`
                                    : ''
                            }
                            alt=""
                        />
                    )}
                    {brandLogo ? (
                        !updataBrandChannelState.logo ? (
                            <MessageBox title={'No available logo yet'} />
                        ) : null
                    ) : !updataBrandChannelState.background ? (
                        <MessageBox title={'No available background yet'} />
                    ) : null}
                </div>
                {isAdmin ? (
                    <MenuItem
                        icon={<IoLogoTableau size={24} />}
                        title={brandLogo ? `Logo` : `Background`}
                        right={
                            <ToolbarIcon
                                accept="image/*"
                                onChange={e => uploadFile(e, brandLogo ? 'logo' : 'background')}
                            >
                                <FiUpload size={20} />
                            </ToolbarIcon>
                        }
                    />
                ) : null}
            </div>
            {isAdmin ? (
                <div className={styles.footer}>
                    <ButtonComponent width="100%" margin="10px 0 0 0" text="Save" clickMe={handleSave} />
                </div>
            ) : null}
        </>
    );
};

export default observer(BrandGroupLogoAndBack);
