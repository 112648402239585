import MessageDoc from '../../../../components/Chat/MessageDoc';
import Text from '../../../../components/Text/Text';
import { RawMessage } from '../../../../types/channel';
import styles from '../GroupMedia.module.css';

type PropsList = {
    data: RawMessage[];
};

const DocsList: React.FC<PropsList> = ({ data }) => {
    return (
        <div className={styles.listVideo} style={{ justifyContent: 'center' }}>
            {data.length === 0 && (
                <div style={{ width: '100%' }}>
                    <Text fontSize="14px" center>
                        No Doc
                    </Text>
                </div>
            )}
            {data.map(doc => {
                return (
                    <div key={doc.id} className={styles.listDocItem}>
                        <MessageDoc
                            mediaLocation={doc.mediaUrl}
                            mediaTitle={doc.mediaTitle}
                            loading={false}
                            status={'Download'}
                            message={doc}
                            inList={true}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default DocsList;
