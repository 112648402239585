import { Spin, Tag, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { InputComponent } from '../components/InputSearch/inputComponent';
import SmallAvatar from '../components/SmallAvatar/smallAvatar';
import Text from '../components/Text/Text';
import { TMP_URL } from '../env';
import useRootStore from '../hooks/useRootStore';
import { ArrowDownIcon, ArrowUpIcon, CloserNoCirculIcon, HashtagIcon, SearchIcon } from './icons';
import { MdLocationPin } from 'react-icons/md';
import Colors from './colors';

const ChatHeader = () => {
    const [isOpenAllHashTags, setIsOpenAllHashTags] = React.useState<boolean>(false);
    const { visible, toglevisible } = useRootStore().visibleStore;
    const { openRightSideBar } = useRootStore().routerStore;
    const { setSearch, searchMessage, slug, clearSearch, searchMessageState, setSearchMessageId, searchMessages } =
        useRootStore().messageStore;

    const {
        hashTags,
        removeHashTags,
        getChannelAllHashTags,
        isLoading,
        allChatHashTags,
        enter,
        setHashTags,
        isOpenHashTagScreen,
    } = useRootStore().hashtagStore;

    const { getSelectedChannelData } = useRootStore().channelStore;
    const navigate = useNavigate();
    const { name } = useParams();
    const [searchCount, setSearchCount] = useState(0);

    useEffect(() => {
        setSearchMessageId('');
        setSearchCount(0);
    }, [searchMessages.count]);

    useEffect(() => {
        setSearchMessageId(searchMessages.messages[searchCount]?.id);
    }, [searchCount]);

    const handleHashTagClick = (tag: string) => {
        if (isOpenHashTagScreen) {
            setHashTags(tag);
            enter();
        } else {
            setHashTags(tag);
            enter();
            navigate(
                generatePath('/:name', {
                    name: name || '',
                }) +
                    generatePath('/:hashtag', {
                        hashtag: tag,
                    }),
            );
        }
    };

    const searchHandle = (e: string) => {
        setSearch(e);
        searchMessage(e, slug);
    };

    const ToggleSearchInput = () => {
        clearSearch();
        toglevisible('setSearch');
    };

    const OpenManageChannel = () => {
        openRightSideBar();
    };

    const handleClose = (removedTag: string) => {
        removeHashTags(removedTag);
        handleHashTagClick(removedTag);
    };

    const prevNextSearchMessage = useCallback(() => {
        if (searchMessages.count > searchCount) {
            setSearchCount(prev => prev + 1);
        }
    }, [searchMessages.messages, searchCount]);

    const prevSearchMessage = useCallback(() => {
        if (searchCount > 0) {
            setSearchCount(prev => (prev > 0 ? prev - 1 : 0));
        }
    }, [searchMessages.messages, searchCount]);

    return (
        <BassComponent>
            <div className="container">
                <header>
                    <div onClick={OpenManageChannel}>
                        <SmallAvatar
                            imageUrl={
                                getSelectedChannelData.avatar ? `${TMP_URL}/${getSelectedChannelData.avatar}` : ''
                            }
                            color={getSelectedChannelData.color || ''}
                        />
                        <div className="channelRowName">
                            <h3 style={{ fontWeight: '600', fontSize: '18px' }}>{getSelectedChannelData.name}</h3>
                            {!!getSelectedChannelData.rByLocationType?.length && (
                                <span className="channelLocation">
                                    <MdLocationPin color={Colors.DarkGray} size={11} />
                                    <Text
                                        children={'Relevance is calculated by location'}
                                        color={Colors.DarkGray}
                                        fontSize="11px"
                                    />
                                </span>
                            )}
                        </div>
                    </div>
                </header>
                <div>
                    <button
                        style={{
                            outline: 'none',
                            border: 'none',
                            background: 'transparent',
                        }}
                        onClick={() => {
                            getChannelAllHashTags();
                            setIsOpenAllHashTags(prev => !prev);
                        }}
                    >
                        {isLoading && allChatHashTags.length === 0 ? (
                            <Spin />
                        ) : (
                            <>
                                {isOpenAllHashTags ? (
                                    <CloserNoCirculIcon size={24} color="#303030" />
                                ) : (
                                    <HashtagIcon size={24} color="#303030" />
                                )}
                            </>
                        )}
                    </button>
                    <button
                        style={{
                            outline: 'none',
                            border: 'none',
                            background: 'transparent',
                        }}
                        onClick={ToggleSearchInput}
                    >
                        {visible.setSearch ? (
                            <CloserNoCirculIcon size={24} color="#303030" />
                        ) : (
                            <SearchIcon size={24} color="#303030" />
                        )}
                    </button>
                </div>
            </div>
            <HashTagContainer $isHas={allChatHashTags.length > 0 && isOpenAllHashTags}>
                <Text fontSize="12px" fontFamily="Montserrat" fontWeight={600} margin="0 5px 0 0">
                    All hashtags:
                </Text>
                {allChatHashTags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag key={index} onClose={() => handleClose(tag)} style={{ cursor: 'pointer' }}>
                            <Text
                                handleLink={() => handleClose(tag)}
                                fontSize="12px"
                                fontFamily="Montserrat"
                                fontWeight={600}
                                margin="0"
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                #{isLongTag ? `${tag.slice(0, 20)}...` : tag}
                            </Text>
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
                {/* <GrFormClose
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                    }}
                    size={24}
                    color="#303030"
                    onClick={() => setIsOpenAllHashTags(!isOpenAllHashTags)}
                /> */}
            </HashTagContainer>
            <HashTagContainer $isHas={hashTags.length > 0}>
                <Text fontSize="12px" fontFamily="Montserrat" fontWeight={600} margin="0 5px 0 0">
                    Created hashtags:
                </Text>
                {hashTags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag key={index} closable onClose={() => handleClose(tag)}>
                            <Text
                                fontSize="12px"
                                fontFamily="Montserrat"
                                fontWeight={600}
                                margin="0"
                                style={{
                                    cursor: 'auto',
                                }}
                            >
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                            </Text>
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
            </HashTagContainer>
            <SearchContainer $isHas={visible.setSearch}>
                <InputComponent
                    onChangeText={e => searchHandle(e)}
                    backColor="transparent"
                    width="85%"
                    value={searchMessageState}
                    placeholder={'Search message...'}
                />
                <Text>
                    {searchCount}/{searchMessages.count}
                </Text>
                <div className="arrows">
                    <ArrowUpIcon onClick={prevNextSearchMessage} />
                    <ArrowDownIcon onClick={prevSearchMessage} />
                </div>
            </SearchContainer>
        </BassComponent>
    );
};

export default observer(ChatHeader);

const BassComponent = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: auto;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(25px);
    z-index: 15;
    box-shadow:
        0px 8px 48px 0px rgba(32, 35, 39, 0.02),
        0px 4px 8px 0px rgba(32, 35, 39, 0.04),
        0px 0px 1px 0px rgba(32, 35, 39, 0.16);

    .container {
        width: 100%;
        height: 7.5vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 15px;

        div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        span {
            display: flex;
            align-items: center;
            gap: 2px;
        }

        .channelRowName {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 0;
        }

        .channelNameBox {
            display: flex;
            gap: 5px;
            align-items: center;
        }
    }

    header {
        width: 70%;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 5px;

        div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        h3 {
            width: 450px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        nav {
            position: relative;
            margin-left: 5px;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
            img {
                position: absolute;
                width: 45px;
                height: 45px;
                object-fit: cover;
            }
        }
    }
`;

const HashTagContainer = styled.div<{ $isHas: boolean }>`
    width: 100%;
    height: auto;
    padding: 5px;
    display: ${({ $isHas }) => ($isHas ? 'flex' : 'none')};
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;

    border-top: 0.8px solid #d2d2d2;

    &::-webkit-scrollbar {
        height: 2px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #d2d2d2;
    }

    .ant-tag {
        padding: 0px 7px 0px 7px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const SearchContainer = styled.div<{ $isHas: boolean }>`
    width: 100%;
    height: auto;
    padding: 5px;
    display: ${({ $isHas }) => ($isHas ? 'flex' : 'none')};
    align-items: center;
    justify-content: space-between;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    gap: 3px;

    border-top: 0.8px solid #d2d2d2;

    &::-webkit-scrollbar {
        height: 2px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #d2d2d2;
    }
    .arrows {
        display: flex;
    }

    .ant-tag {
        padding: 0px 7px 0px 7px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
