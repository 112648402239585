export const es = {
    welcome_text: 'Bienvenido a ChatXO',
    login: 'Acceso',
    loginScreen: 'Login with Email',
    create_account: 'Crear una cuenta',
    change_language: 'Idiomas',
    english_language: 'Inglés',
    german_language: 'Aleman',
    french_language: 'Francés',
    italian_language: 'Italiano',
    spanish_language: 'Española',
    portuguese_language: 'Portuguesa',
    username: 'Usuario',
    your_email: 'Tu email',
    password: 'Contraseña',
    your_password: 'Tu contraseña',
    sign_up: 'Registrarse ',
    sign_up_header: 'Create an account',
    sign_up_email: 'Regístrese con el correo electrónico',
    sign_up_header_username: 'Introduzca un nombre de usuario único',
    groups: 'Grupos',
    group: 'Grupo',
    no_avalible_groups: 'No hay grupos disponibles',
    private_group: 'Private group',
    friends: 'Amigos',
    friend: 'Amigo',
    filter: 'Filtro',
    add_friends: 'Agregar amigos',
    add: 'Agregar',
    added: 'Agregado',
    add_to_channel: '¿Quieres añadir {{username}} to {{channelName}}?',
    delete_from_channel: '¿Quiere eliminar {{username}} de {{channelName}}?',
    invite: 'Invitar',
    discover: 'Descubrir',
    create_channel: 'Crear grupo',
    edit_profile: 'Editar perfil',
    group_name: 'Nombre de grupo',
    invite_friends: 'Invitar amigos',
    no_avalible_friends: 'No hay amigos disponibles',
    create: 'Crear',
    create_channel_req: 'Se requiere nombre del grupo',
    edit_channel_name: 'Editar nombre del grupo',
    save: 'Guardar',
    select_image: 'Seleccionar imagen',
    random_color: 'Colores aleatorios',
    leave_group: 'Abandonar grupo',
    add_hashtag: 'Añadir  hashtag',
    done: 'Hecho',
    take_photo: 'Tomar foto',
    photo_gallery: 'Elige una foto de la galería',
    video_gallery: 'Elige un video de la galería',
    choose_document: 'Choose document',
    audio: 'Audio',
    cancel: 'Cancelar',
    download: 'Descargar',
    play: 'Reproducir',
    pause: 'Pausa',
    media_player: 'Reproductor multimedia',
    account: 'Cuenta',
    settings: 'Configuraciones',
    relevance: 'My judgement ',
    in_channels: 'en grupos',
    per_user: 'por usuario',
    empty_rel_channles: 'Sin relevancia por grupos',
    empty_rel_users: 'Sin relevancia por amigos',
    no_avalible_channels: 'No hay grupos disponibles',
    unfriend: 'desagregar',
    press_to_change: 'Arrastrar para editar',
    error_message_username_unique: 'Username is not unique',
    error_message_username_req: 'Se requiere usuario',
    error_message_username_min: 'Usuario debe tener al menos 3 caracteres de longitud',
    error_message_username_regex: 'Usuario no puede ser una combinación de caracteres y dígitos especiales',
    error_message_email_req: 'Email es necesario',
    error_message_email_form: 'Formato de email inválido',
    error_message_password_regex:
        'La contraseña debe ser una combinación de letras mayúsculas y minúsculas, dígitos y caracteres especiales',
    error_message_password_min: 'La contraseña debe tener al menos 8 caracteres',
    error_message_password_req: 'Se requiere una contraseña',
    error_message_groupName_req: 'El primer símbolo no puede ser un espacio',
    error_type: 'El formato GIF no está permitido',
    update_profile: 'Actualiza tu perfil',
    update_relevance: 'Actualizar relevancia',
    image_picker_error: 'ImagePicker Error: ',
    join: 'Uniéndose a...',
    join_group: 'Únete al grupo',
    file_size_error: 'Este archivo no puede ser cargado',
    file_size_error_big: 'El archivo es demasiado grande.',
    unknown_error: 'Error desconocido:',
    no_discover_channels: 'No hay nuevos grupos por descubrir',
    error: 'Error',
    permissions_storage_android: 'ChatXO necesita acceso a tu almacenamiento',
    permissions_storage_write_android_message:
        'La aplicación necesita acceder a tu almacenamiento de archivos para poder descargar el archivo',
    no_permissions: 'Permiso no concedido',
    how_to_enable: 'Ir a la configuración para habilitar el almacenamiento de archivos',
    how_to_enable_microphone: 'Ir a la configuración para activar el micrófono',
    permissions_storage_read_android_message:
        "La aplicación necesita acceso a tu almacenamiento de archivos para poder reproducir el archivo'.'",
    permissions_microphone_android: 'ChatXO needs access to your microphone',
    permissions_microphone_android_message: 'La aplicación necesita acceso a tu micrófono para poder grabar un archivo',
    type_message: 'Escribir mensaje',
    relevance_in: 'Relevancia en',
    delete: 'Borrar',
    report: 'Informe',
    copy: 'Copiar',
    pinMessage: 'Mensaje de la clavija',
    unpinMessage: 'Desenganchar el mensaje',
    reply: 'Respuesta',
    reportMessage: 'El mensaje ha sido reportado con éxito',
    remove_image: 'Quitar la imagen',
    ok: 'Ok',
    take_video: 'Graba un vídeo',
    max_username_chars: 'El nombre de usuario debe tener un máximo de 20 caracteres',
    hashtag_already_exists: 'El hashtag ya existe',
    press_to_record: 'Press and hold to record',
    signUpSucc_title: 'Mantenga pulsado para grabar',
    signUpSucc_message: 'Gracias por su registro. Su cuenta ya está lista para ser utilizada. ',
    personal_relevance: 'Relevancia personal',
    go_to_profile: 'Ir al perfil',
    no_longer_in_group: 'El usuario ya no está en el grupo',
    invited: 'Invitado',
    groupsNumber: 'Número del grupo',
    or: 'o',
    scanner: 'Escáner',
    groupsInvite: 'Contraseña del grupo',
    groupsName: 'Nombre del grupo',
    inviteUsers: 'Invitar a los usuarios',
    createGroup: 'Crear un nuevo grupo',
    generateNew: 'Generar nuevo',
    noCode: 'Sin código',
    groupsPassword: 'Contraseña',
    noPassword: 'No Contraseña',
    editGroup: 'Editar grupo',
    blockedUsers: 'Usuarios bloqueados',
    no_blocked_users: 'No hay usuarios bloqueados',
    block_user_dialog: '¿Quieres bloquear a {{username}} en {{channelName}}?',
    unblock_user_dialog: '¿Quieres desbloquear a {{username}} en {{channelName}}?',
    block_user_button: 'Bloquear usuario',
    unblock_user_button: 'Desbloquear',
    blocked_user_anuthorized: 'Has sido bloqueado en el grupo. Por favor, ponte en contacto con el administrador',
    manageUsers: 'Gestionar usuarios',
    deleteGroup: 'Borrar el grupo',
    update: 'Actualización',
    addParticipant: 'Añadir participante',
    shareGroup: 'Comparta su grupo',
    complete: 'Completa',
    link: 'Enlace',
    copyLink: 'Copiar el enlace del chat',
    share: 'Compartir el chat',
    enterName: 'Introduzca un nombre',
    uploadPhoto: 'Subir foto',
    linkCopied: 'Enlace copiado con éxito',
    msgCopied: 'El mensaje ha sido copiado',
    login_fb: 'Continuar con Facebook',
    login_google: 'Continuar con Google',
    login_apple: 'Continuar con Apple',
    web_err_title: 'Error',
    web_err_msg: 'Error al cargar la página web',
    forgot_pass: 'Olvidé mi contraseña',
    forgot_text: 'Olvidé mi contraseña',
    reset_btn: 'Enviar',
    success_alert_title: 'Éxito',
    reset_alert_msg: 'Comprueba tu correo electrónico, habrá una nueva contraseña.',
    error_alert_title: 'Error',
    apple_auth_err_msg: 'Error al generar el token',
    or_social_auth: 'o',
    error_google_auth_play_service: 'Error en los servicios de google play',
    error_google_auth_other: 'Error',
    error_fb_auth: 'Error',
    error_fb_auth_get_user: 'Se produce un error al obtener la información del usuario',
    error_channel_404: 'Este canal aún no existe',
    error_toast: 'No se ha encontrado',
    option_search: 'Busque en',
    option_groupInfo: 'Información del grupo',
    option_reportChat: 'Informar sobre el chat',
    option_leaveChat: 'Dejar el grupo',
    delete_from_friends: '¿Quieres eliminar a {{username}} de los amigos?',
    leave_title: 'Dejar el grupo',
    leave_text: "¿Estás seguro de que quieres dejar '{{channelName}}'?",
    overlayDesc:
        'Aquí puedes establecer la relevancia mínima que deben tener los usuarios para poder leer tus mensajes',
    judgmentOvelayDrag: 'Arrastrar para cambiar',
    removeFriend: 'eliminado',
    groupInfo: 'Información del grupo',
    searchPlaceholder: 'Buscar...',
    sendInvite: 'Envíe invitaciones a su grupo',
    addFriend: 'añadir amigo',
    rmFriend: 'eliminar la amistad de',
    myJudgment: 'Mi juicio',
    pollTitle: 'Nueva encuesta',
    pollQuestion: 'Pregunta de la encuesta',
    enterPollQuestion: 'Introduzca la pregunta de la encuesta',
    loading: 'Cargando',
    poll: 'Encuesta',
    byRelevance: '(por relevancia)',
    votes: 'votos',
    noVotes: 'no votos',
    addOptionsCounter: 'Puedes añadir {{value}} más opciones',
    addOption: 'Añadir una opción',
    resultByRelevance: 'Mostrar resultado por relevancia',
    resultByNumberOfVotes: 'Mostrar el resultado por número de votos',
    bigPoll: 'ENCUESTA',
    enterAnswer: 'Introduzca la respuesta',
    vote: 'Vote',
    voted: 'Votado',
    errorPollInputs: 'Por favor, rellene todos los campos',
    byRelevanceTitle: 'Por relevancia',
    byNumberOfVotesTitle: 'Por número de votos',
    showPoll: 'Mostrar encuesta {{poll}}',
    newAdmin: 'Nueva administración',
    newAdminDescription: '¿Quieres poner a {{name}} como nuevo administrador en el chat de "{{channel}}"?',
    system_message_admin_changed: '{{name}} es un nuevo Admin',
    system_message_user_joined: '{{name}} se unió al chat',
    system_message_user_left: '{{name}} salió del chat',
    system_message_user_blocked: '{{name}} fue bloqueado en el chat',
    system_message_user_unblocked: '{{name}} fue desbloqueado en el chat',
    system_message_user_was_added: '{{name}} se añadió al chat',
    type_group: "Group's Type: {{groupType}}",
    group_is_private: 'Only the Admin of the chat can invite a new participant',
    group_is_open: 'Any user of the chat can invite a new participant',
    join_private_group:
        'Está intentando entrar en el chat privado. Por favor, póngase en contacto con el administrador del chat',
    voted_option_title: 'Votó por la opción {{name}}',
    nobody_voted: 'Nadie ha votado por la opción',
    seconds: 'segundos',
    second: 'segundo',
    minutes: 'minutos',
    minute: 'minuto',
    hours: 'horas',
    hour: 'hora',
    one_member: 'miembro',
    many_members: 'miembros',
    country: 'país',
    city: 'ciudad',
    district: 'distrito',
    place: 'lugar',
    byWithProp: 'Por {{prop}}',
    enabled: 'habilitado',
    disabled: 'desactivado',
    relevanceByLocation: 'Relevancia por lugar: {{type}}',
};
