import React, { useState } from 'react';
import Text from '../components/Text/Text';
import style from './index.module.css';

type PropsMediaTitle = {
    mediaTitle: string;
};

export const MediaTitleText: React.FC<PropsMediaTitle> = ({ mediaTitle }) => {
    const [visible, setVisible] = useState(false);
    const handleShow = () => {
        setVisible(show => !show);
    };
    return (
        <div>
            <Text fontSize="14px" fontWeight={600}>
                {mediaTitle?.length ? (
                    <>
                        {mediaTitle.length > 210 ? mediaTitle.slice(0, 210) : mediaTitle}{' '}
                        {!visible ? (
                            mediaTitle.length > 210 && (
                                <span onClick={handleShow} className={style.show}>
                                    ...show
                                </span>
                            )
                        ) : (
                            <>
                                {mediaTitle.slice(210, mediaTitle.length)}
                                <span onClick={handleShow} className={style.show}>
                                    hide
                                </span>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        Brain buffering! Almost there
                        <span className="dot-ellipsis">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </span>
                    </>
                )}
            </Text>
        </div>
    );
};
