import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import MessageComponent from '../../../components/Chat/MessageComponent/MessageComponent';
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer';
import useRootStore from '../../../hooks/useRootStore';
import ChatHeaderHashtag from '../../../utils/chatHeaderHashtag';
import MessageInput from './components/messageInput/MessageInput';

const ChatTag = () => {
    const navigate = useNavigate();
    const { allHashTagsMessages, exit } = useRootStore().hashtagStore;
    const { messagesFilterValue, setIsMessagesLength, setChannelSlug, exitChannelEmit } = useRootStore().messageStore;
    const { getSlectedChannelUsers, channelData } = useRootStore().channelStore;
    const { oneToOne } = useRootStore().chatStore;
    const { oneToOneUser, setOneToOne, clearTagMessages, setIsOpenTagScreen, isOpenTagScreen, tagsMessages } =
        useRootStore().channelSocketStore;
    const { user } = useRootStore().authStore;
    const { name } = useParams();

    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                exitChannelEmit();
                navigate(
                    generatePath('/:name', {
                        name: name || '',
                    }),
                );
                setOneToOne({ id: 0, username: '' });
                setIsOpenTagScreen(false);
                setChannelSlug('');
                clearTagMessages();
            }
        };
        window.addEventListener('keydown', handleEsc);
        window.addEventListener('popstate', exit);

        return () => {
            window.removeEventListener('keydown', handleEsc);
            window.addEventListener('popstate', exit);
        };
    }, [exit, navigate]);

    useEffect(() => {
        if (isOpenTagScreen) {
            if (oneToOneUser.id) {
                oneToOne({
                    channelSlug: channelData.slug,
                    taggedUserId: oneToOneUser.id,
                    userId: user.id,
                    pageState: channelData.pageState,
                });
            }
        }
    }, [oneToOneUser, isOpenTagScreen]);

    console.log(JSON.stringify(oneToOneUser));

    const messages = useMemo(() => {
        const messagesData = allHashTagsMessages.messages;
        if (messagesData?.length === 0) {
            setIsMessagesLength(true);
            return [];
        }
        if (messagesFilterValue !== 0) {
            setIsMessagesLength(false);
            return messagesData.filter(e => e.relevance && e.relevance >= messagesFilterValue);
        }
        setIsMessagesLength(false);
        return messagesData;
    }, [allHashTagsMessages.messages, messagesFilterValue, setIsMessagesLength]);

    return (
        <ChatContainer id="chatView">
            <ChatHeaderHashtag />
            <ScrollContainer>
                {_.map(tagsMessages.messages, (message, index) => {
                    return (
                        <MessageComponent
                            isLast={0 === index}
                            isFirst={tagsMessages.messages?.length - 1 === index}
                            _ref={ref => {}}
                            key={message.id}
                            message={message}
                            users={getSlectedChannelUsers}
                        />
                    );
                })}
            </ScrollContainer>
            <MessageInput />
        </ChatContainer>
    );
};

export default observer(ChatTag);

const ChatContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;
