export const en = {
    welcome_text: 'Welcome to ChatXO',
    login: 'Login',
    loginScreen: 'Login with Email',
    create_account: 'Create an account',
    change_language: 'Languages',
    english_language: 'English',
    german_language: 'German',
    french_language: 'French',
    italian_language: 'Italian',
    spanish_language: 'Spanish',
    portuguese_language: 'Portuguese',
    username: 'Unique User Name',
    your_email: 'Your Email',
    password: 'Password',
    your_password: 'Your Password',
    sign_up: 'Sign Up',
    sign_up_header: 'Create Account',
    sign_up_email: 'Register with Email',
    sign_up_header_username: 'Enter unique username',
    groups: 'Groups',
    group: 'group',
    no_avalible_groups: 'No available groups',
    private_group: 'Private group',
    friends: 'Friends',
    friend: 'friend',
    filter: 'Filter',
    add_friends: 'Add Friends',
    add: 'Add',
    added: 'Added',
    audio_m: 'Audio',
    docs: 'Docs',
    video: 'Video',
    image: 'Image',
    add_to_channel: 'Do you want to add {{username}} to {{channelName}}?',
    delete_from_channel: 'Do you want to remove {{username}} from {{channelName}}?',
    invite: 'Invite',
    discover: 'Discover',
    create_channel: 'Create group',
    edit_profile: 'Edit profile',
    group_name: 'Group name',
    invite_friends: 'Invite friends',
    no_avalible_friends: 'No available friends',
    create: 'Create',
    create_channel_req: 'Group name is required',
    edit_channel_name: 'Edit group name',
    save: 'Save',
    select_image: 'Select image',
    random_color: 'Random color',
    leave_group: 'Leave group',
    add_hashtag: 'Created hashtags',
    done: 'Done',
    take_photo: 'Take a photo',
    photo_gallery: 'Choose photo from gallery',
    video_gallery: 'Choose video from gallery',
    choose_document: 'Choose document',
    audio: 'Choose audio',
    cancel: 'Cancel',
    download: 'Download',
    play: 'Play',
    pause: 'Pause',
    media_player: 'Media Player',
    account: 'Account',
    settings: 'Settings',
    relevance: 'My judgement ',
    in_channels: 'in groups',
    per_user: 'per user',
    empty_rel_channles: 'No relevance per groups',
    empty_rel_users: 'No relevance per friends',
    no_avalible_channels: 'No available groups',
    unfriend: 'Unfriend',
    press_to_change: 'press to change',
    error_message_username_unique: 'Username is not unique',
    error_message_username_req: 'Username is required',
    error_message_username_min: 'Username should be at least 3 character long',
    error_message_username_regex: 'Username cannot be a combination of special characters and digits',
    error_message_email_req: 'Email is required',
    error_message_email_form: 'Invalid email format',
    error_message_password_regex:
        'Password should be a combination of uppercase and lowercase letters, digits and special characters',
    error_message_password_min: 'Password should be at least 8 character long',
    error_message_password_req: 'Password is required',
    error_message_groupName_req: 'The first symbol cannot be a space',
    error_type: 'GIF format is not allowed',
    update_profile: 'Update your profile',
    update_relevance: 'Update judgement',
    image_picker_error: 'ImagePicker Error: ',
    join: 'Joining...',
    join_group: 'Join group',
    file_size_error: "This file can't be loaded",
    file_size_error_big: 'The file is too big.',
    unknown_error: 'Unknown Error: ',
    no_discover_channels: 'No new groups to discover',
    error: 'Error',
    permissions_storage_android: 'ChatXO needs access to your storage',
    permissions_storage_write_android_message: 'The app needs access to your file storage so you can download the file',
    no_permissions: 'Permission not granded',
    how_to_enable: 'Go to settings to enable the file storage',
    how_to_enable_microphone: 'Go to settings to enable the microphone',
    permissions_storage_read_android_message: "The app needs access to your file storage so you can play the file'",
    permissions_microphone_android: 'ChatXO needs access to your microphone',
    permissions_microphone_android_message: 'The app needs access to your microphone so you can record a file',
    type_message: 'Type a message...',
    relevance_in: 'Relevance in',
    delete: 'Delete',
    addHashtag: 'Add Hashtag',
    report: 'Report',
    copy: 'Copy',
    pinMessage: 'Pin message',
    unpinMessage: 'Unpin message',
    reply: 'Reply',
    reportMessage: 'Message has been reported successfully',
    remove_image: 'Remove image',
    ok: 'Ok',
    take_video: 'Take a video',
    max_username_chars: 'Username should be maximum 20 characters',
    hashtag_already_exists: 'Hashtag already exists',
    press_to_record: 'Press and hold to record',
    signUpSucc_title: 'Account successfully created',
    signUpSucc_message: 'Thank you for your registration! Your account is now ready to use. ',
    personal_relevance: 'Personal relevance',
    go_to_profile: 'Go to profile',
    no_longer_in_group: 'The user is no longer in the group',
    invited: 'Invited',
    groupsNumber: "Group's Number",
    or: 'or',
    scanner: 'Scanner',
    groupsInvite: 'Invitation Code',
    groupsName: "Group's name",
    inviteUsers: 'Invite users',
    createGroup: 'Create New Group',
    generateNew: 'Generate New',
    noCode: 'No Code',
    groupsPassword: 'Password',
    noPassword: 'No Invitation',
    editGroup: 'Edit group',
    blockedUsers: 'Blocked users',
    no_blocked_users: 'No blocked users',
    block_user_dialog: 'Do you want to block {{username}} in {{channelName}}?',
    unblock_user_dialog: 'Do you want to unblock {{username}} in {{channelName}}?',
    block_user_button: 'Block user',
    unblock_user_button: 'Unblock',
    blocked_user_anuthorized: 'You were blocked in the group. Please, contact the administrator',
    manageUsers: 'Manage users',
    deleteGroup: 'Delete group',
    update: 'Update',
    addParticipant: 'Add Participant',
    brandGroup: 'Brand Group Chat',
    shareGroup: 'Share your group',
    complete: 'Complete',
    link: 'Link',
    copyLink: 'Share chat invite link',
    share: 'Share Chat',
    enterName: 'Enter a name',
    uploadPhoto: 'Upload Photo',
    linkCopied: 'Link copied successfully',
    msgCopied: 'Message has been copied',
    login_fb: 'Continue with Facebook',
    login_google: 'Continue with Google',
    login_apple: 'Continue with Apple',
    web_err_title: 'Error',
    web_err_msg: 'Error while loading web page',
    forgot_pass: 'Forgot password',
    forgot_text: 'Forgot Password',
    reset_btn: 'Send',
    success_alert_title: 'Success',
    reset_alert_msg: 'Check your email, there will be a new password.',
    error_alert_title: 'Error',
    apple_auth_err_msg: 'Error while generating token',
    or_social_auth: 'or',
    error_google_auth_play_service: 'Error in google play services',
    error_google_auth_other: 'Error',
    error_fb_auth: 'Error',
    error_fb_auth_get_user: 'Got error while was getting user info',
    error_channel_404: "This channel doens't exist yet",
    error_toast: 'Not found',
    option_search: 'Search',
    option_groupInfo: 'Group info',
    option_reportChat: 'Report chat',
    option_leaveChat: 'Leave group',
    delete_from_friends: 'Do you want to remove {{username}} from friends?',
    leave_title: 'Leave group',
    leave_text: "Are you sure you want to leave '{{channelName}}'?",
    overlayDesc: 'Here you can set the minimum relevance users need to have to be able to read your messages',
    judgmentOvelayDrag: 'Drag to change',
    removeFriend: 'removed',
    groupInfo: 'Group Info',
    searchPlaceholder: 'Search...',
    sendInvite: 'Send invitations to your group',
    addFriend: 'add friend',
    rmFriend: 'unfriend',
    myJudgment: 'My judgment',
    pollTitle: 'New poll',
    pollQuestion: 'Poll question',
    enterPollQuestion: 'Enter poll question',
    loading: 'Loading',
    poll: 'Poll',
    byRelevance: '(by relevance)',
    votes: 'votes',
    noVotes: 'no votes',
    addOptionsCounter: 'You can add {{value}} more option',
    addOption: 'Add an option',
    resultByRelevance: 'Show result by relevance',
    resultByNumberOfVotes: 'Show result by number of votes',
    bigPoll: 'POLL',
    enterAnswer: 'Enter answer',
    vote: 'Vote',
    voted: 'Voted',
    errorPollInputs: 'Please, fill all fields',
    byRelevanceTitle: 'By relevance',
    byNumberOfVotesTitle: 'By number of votes',
    showPoll: 'Show poll "{{poll}}"',
    admin: 'admin',
    newAdmin: 'new admin',
    addAdmin: 'add admin',
    moderator: 'moderator',
    newAdminDescription: 'Do you want to set {{name}} as a new admin in "{{channel}}" chat?',
    system_message_admin_changed: '{{name}} is a new Admin',
    system_message_user_joined: '{{name}} joined the chat',
    system_message_user_left: '{{name}} left the chat',
    system_message_user_blocked: '{{name}} was blocked in the chat',
    system_message_user_unblocked: '{{name}} was unblocked in the chat',
    system_message_user_was_added: '{{name}} was added to the chat',
    type_group: "Group's Type: ",
    group_is_private: 'Only the Admin of the chat can invite a new participant',
    group_is_open: 'Any user of the chat can invite a new participant',
    join_private_group: 'You are trying to join the Private group. Please, contact the Administrator of the group',
    voted_option_title: 'Voted for the option {{name}}',
    nobody_voted: 'Nobody has voted for the option',
    seconds: 'seconds',
    second: 'second',
    minutes: 'minutes',
    minute: 'minute',
    hours: 'hours',
    hour: 'hour',
    one_member: 'member',
    many_members: 'members',
    country: 'country',
    city: 'city',
    district: 'district',
    place: 'place',
    byWithProp: 'By {{prop}}',
    enabled: 'enabled',
    disabled: 'disabled',
    relevanceByLocation: 'Relevance by location: {{type}}',
    group_medias: 'Group Medias',
    group_policy: 'Group Privacy Policy',
    import_export: 'Import Export emails',
};
