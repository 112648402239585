import React from 'react';
import styled from 'styled-components';

type Props = {
    children: React.ReactNode;
    onClick: () => void;
    style?: React.CSSProperties;
    rounded?: boolean;
};

function OutlineButton({ children, onClick, style }: Props) {
    return (
        <Button onClick={onClick} style={style} $rounded>
            {children}
        </Button>
    );
}

export default OutlineButton;

const Button = styled.button<{ $rounded?: boolean }>`
    background-color: transparent;
    cursor: pointer;
    border-radius: ${({ $rounded }) => ($rounded ? '50%' : '0')};
    border: none;
`;
