const Assets = {
    tabChats: require('../assets/icons/tab-chats.png'),
    tabDiscover: require('../assets/icons/tab-discover.png'),
    tabFriends: require('../assets/icons/tab-friends.png'),
    topAccount: require('../assets/icons/top-account.png'),
    topAddFriend: require('../assets/icons/top-add-friend.png'),
    arrowLeftSmall: require('../assets/icons/arrow-left-small.png'),
    bootsplash_logo: require('../assets/icons/bootsplash_logo.png'),
    logout: require('../assets/icons/logout.png'),
    g_logo: require('../assets/icons/g_logo.png'),
    f_logo: require('../assets/icons/f_logo.png'),
    tab_chats: require('../assets/icons/tab-chats.png'),
    icon: require('../assets/icons/icon.png'),
    cameraIcon: require('../assets/icons/cameraIcon.png'),
    addgroup: require('../assets/icons/add-group.png'),
    addimg: require('../assets/icons/add-image.png'),
    blind_icon: require('../assets/icons/blind.orig.png'),
    chat_hash: require('../assets/icons/chat-hash@2x.png'),
    filter_icon: require('../assets/icons/filter.png'),
    share_icon: require('../assets/icons/share.png'),
    voise_icon: require('../assets/icons/microphone.png'),
    chart_icon: require('../assets/icons/statIcon.png'),
    videocamera_icon: require('../assets/icons/video_camera.png'),
    send_Icon: require('../assets/icons/chat-send@3x.png'),
    document_icon: require('../assets/icons/document.png'),
    blocket_icon: require('../assets/icons/people.png'),
    copy_icon: require('../assets/icons/copy.png'),
    editgroup: require('../assets/icons/editing.png'),
    search_Icon: require('../assets/icons/search-interface-symbol.png'),
    arrow_up: require('../assets/icons/up-arrow.png'),
    arrow_down: require('../assets/icons/arrow-down.png'),
    download: require('../assets/icons/downloads.png'),
    starIcon: require('../assets/icons/star.png'),
    up_downIcon: require('../assets/icons/up-down.png'),
    setting: require('../assets/icons/setting.png'),
    isReplyCencel: require('../assets/icons/cancel.png'),
    upload: require('../assets/icons/upload.png'),
    check: require('../assets/icons/check.png'),
    EngIcon: require('../assets/icons/eng.png'),
    ItIcon: require('../assets/icons/it.png'),
    GerIcon: require('../assets/icons/ger.png'),
    SpIcon: require('../assets/icons/sp.png'),
    Poricon: require('../assets/icons/por.png'),
    FrIcon: require('../assets/icons/fr.png'),
};

export default Assets;
