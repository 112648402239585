import Text from '../../../../components/Text/Text';
import { Env } from '../../../../env';
import { RawMessage } from '../../../../types/channel';
import styles from '../GroupMedia.module.css';

type PropsList = {
    data: RawMessage[];
    previewImage: (value: any) => void;
};

const VideoList: React.FC<PropsList> = ({ data, previewImage }) => {
    return (
        <div className={styles.listVideo}>
            {data.length === 0 && (
                <div style={{ width: '100%' }}>
                    <Text fontSize="14px" center>
                        No Video
                    </Text>
                </div>
            )}
            {data.map(video => {
                return (
                    <div key={video.id} className={styles.listVideoItem}>
                        <div className={styles.image}>
                            <video onClick={() => previewImage(video)} src={Env.AssetsUrl + '/' + video.mediaUrl} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default VideoList;
