export const it = {
    welcome_text: 'Benvenuto su ChatXO',
    login: 'Login',
    loginScreen: "Accedi con l'email",
    create_account: 'Crea un account',
    change_language: 'Lingue',
    english_language: 'Inglese',
    german_language: 'Tedesco',
    french_language: 'Francese',
    italian_language: 'Italiano',
    spanish_language: 'Spagnolo',
    portuguese_language: 'Portoghese',
    username: 'Nome utente unico',
    your_email: 'La tua email',
    password: 'Password',
    your_password: 'La tua password',
    sign_up: 'Iscriviti',
    sign_up_header: 'Crea un account',
    sign_up_email: 'Registrati con il tuo indirizzo e-mail',
    sign_up_header_username: 'Introduci un nome utente unico',
    groups: 'Gruppi',
    group: 'gruppo',
    no_avalible_groups: 'Nessun gruppo disponibile',
    private_group: 'Gruppo privato',
    friends: 'Amici',
    friend: 'amico',
    filter: 'Filtro',
    add_friends: 'Aggiungi amici',
    add: 'Aggiungi',
    added: 'Aggiunto',
    add_to_channel: 'Vuoi aggiungere {{username}} a {{channelName}}?',
    delete_from_channel: 'Vuoi rimuovere {{username}} da {{channelName}}?',
    invite: 'Invita',
    discover: 'Scoprire',
    create_channel: 'Crea gruppo',
    edit_profile: 'Modifica profilo',
    group_name: 'Nome del gruppo',
    invite_friends: 'Invita amici',
    no_avalible_friends: 'Nessun amico disponibile',
    create: 'Crea',
    create_channel_req: 'Il nome del gruppo è richiesto',
    edit_channel_name: 'Modifica nome gruppo',
    save: 'Salva',
    select_image: 'Seleziona immagine',
    random_color: 'Colore casuale',
    leave_group: 'Lascia gruppo',
    add_hashtag: 'Aggiungi hashtag',
    done: 'Fatto',
    take_photo: 'Scatta una foto',
    photo_gallery: 'Scegli una foto dalla galleria',
    video_gallery: 'Choose video from gallery',
    choose_document: 'Scegliere documento',
    audio: "Scegli l'audio",
    'cancel ': 'Annulla',
    'download ': 'Scarica',
    play: 'Gioca',
    pause: 'Pausa',
    media_player: 'Media Player',
    account: 'Account',
    settings: 'Impostazioni',
    visibility: 'Visibilità',
    in_channels: 'in gruppi',
    per_user: 'per utente',
    empty_rel_channles: 'Nessuna rilevanza per gruppi',
    empty_rel_users: 'Nessuna rilevanza per amici',
    no_avalible_channels: 'Nessun gruppo disponibile',
    unfriend: 'Unfriend',
    press_to_change: 'premere per cambiare',
    error_message_username_unique: 'Il nome utente non è unico',
    error_message_username_req: 'Il nome utente è richiesto',
    error_message_username_min: 'Il nome utente deve essere lungo almeno 3 caratteri',
    error_message_username_regex: 'Il nome utente non può essere una combinazione di caratteri speciali e cifre',
    error_message_email_req: "L'email è richiesta",
    error_message_email_form: 'Formato email non valido',
    error_message_password_regex:
        'La password deve essere una combinazione di lettere maiuscole e minuscole, cifre e caratteri speciali',
    error_message_password_min: 'La password dovrebbe essere lunga almeno 8 caratteri',
    error_message_password_req: 'La password è richiesta',
    error_message_groupName_req: 'Il primo simbolo non può essere uno spazio',
    error_type: 'Il formato GIF non è consentito',
    update_profile: 'Aggiorna il tuo profilo',
    update_relevance: 'Aggiorna il giudizio',
    image_picker_error: 'Errore di ImagePicker: ',
    join: 'Unirsi...',
    join_group: 'Unisciti al gruppo',
    file_size_error: 'Questo file non può essere caricato',
    file_size_error_big: 'Il file è troppo grande',
    unknown_error: 'Errore sconosciuto: ',
    no_discover_channels: 'Nessun nuovo gruppo da scoprire',
    error: 'Errore',
    permessi_storage_android: 'ChatXO ha bisogno di accedere al tuo storage',
    permissions_storage_write_android_message:
        "L'app ha bisogno di accedere al tuo archivio per poter scaricare il file",
    no_permissions: 'Permesso non concesso',
    how_to_enable: "Vai alle impostazioni per abilitare l'archiviazione dei file",
    how_to_enable_microphone: 'Vai alle impostazioni per abilitare il microfono',
    permissions_storage_read_android_message:
        "L'app ha bisogno di accedere al tuo archivio file per poter riprodurre il file",
    permissions_microphone_android: 'ChatXO ha bisogno di accedere al tuo microfono',
    'permissions_microphone_android _message':
        "L'app ha bisogno di accedere al tuo microfono per poter registrare un file",
    type_message: 'Scrivi un messaggio...',
    relevance_in: 'Rilevanza in',
    delete: 'Elimina',
    report: 'Rapporto',
    copy: 'Copia',
    pinMessage: 'Messaggio pin',
    unpinMessage: 'Disconnetti messaggio',
    reply: 'Risposta',
    reportMessage: 'Il messaggio è stato segnalato con successo',
    remove_image: 'Rimuovi immagine',
    ok: 'Ok',
    take_video: 'Fai un video',
    max_username_chars: 'Il nome utente deve contenere al massimo 20 caratteri',
    hashtag_already_exists: "L'hashtag esiste già",
    press_to_record: 'Tenere premuto per registrare',
    signUpSucc_title: 'Account creato con successo',
    signUpSucc_message: "Grazie per la tua registrazione! Il tuo account è ora pronto per l'uso.",
    personal_relevance: 'Pertinenza personale',
    go_to_profile: 'Vai al profilo',
    no_longer_in_group: "L'utente non è più nel gruppo",
    invited: 'Invitato',
    groupsNumber: 'Numero del gruppo',
    or: 'o',
    scanner: 'scanner',
    groupsInvite: 'Password del gruppo',
    groupsName: 'Nome del gruppo',
    inviteUsers: 'Invita utenti',
    createGroup: ' Crea nuovo gruppo ',
    generateNew: 'Genera nuovo',
    noCode: 'NoCode',
    groupsPassword: 'Password',
    noPassword: 'Nessuna password',
    editGroup: 'Modifica gruppo',
    blockedUsers: 'Utenti bloccati',
    no_blocked_users: 'Nessun utente bloccato',
    block_user_dialog: 'Vuoi bloccare {{username}} in {{channelName}}?',
    unblock_user_dialog: 'Vuoi sbloccare {{username}} in {{channelName}}?',
    block_user_button: 'Blocca utente',
    unblock_user_button: 'Sblocca',
    blocked_user_anuthorized: "Sei stato bloccato nel gruppo. Per favore, contatta l'amministratore",
    manageUsers: 'Gestisci utenti',
    deleteGroup: 'Elimina gruppo',
    update: 'aggiornamento',
    addParticipant: 'AddParticipant',
    shareGroup: 'Condividi il tuo gruppo',
    ' complete ': 'completo',
    link: 'collegamento',
    copyLink: 'Copia collegamento chat',
    share: 'Condividi chat',
    enterName: 'Inserisci un nome',
    uploadPhoto: 'Carica foto',
    linkCopied: 'Link copiato con successo',
    msgCopied: 'Il messaggio è stato copiato',
    login_fb: 'Continua con Facebook',
    login_google: 'Continua con Google',
    login_apple: 'Continua con Apple',
    web_err_title: 'Errore',
    web_err_msg: 'Errore durante il caricamento della pagina web',
    forgot_pass: 'Password dimenticata',
    forgot_text: 'Password dimenticata',
    reset_btn: 'Invia',
    success_alert_title: 'Successo',
    reset_alert_msg: 'Controlla la tua email, ci sarà una nuova password.',
    error_alert_title: 'Errore',
    apple_auth_err_msg: 'Errore durante la generazione del token',
    or_social_auth: 'o',
    error_google_auth_play_service: 'Errore nei servizi di Google Play',
    error_google_auth_other: 'Errore',
    error_fb_auth: 'Errore',
    error_fb_auth_get_user: "Ho ricevuto un errore durante il recupero delle informazioni sull'utente",
    error_channel_404: 'Questo canale non esiste ancora',
    error_toast: 'Non trovato',
    option_search: 'Cerca',
    option_groupInfo: 'Informazioni sul gruppo',
    option_reportChat: 'Segnala chat',
    option_leaveChat: 'Lascia il gruppo',
    delete_from_friends: 'Vuoi rimuovere {{username}} dagli amici?',
    leave_title: 'Lascia il gruppo',
    leave_text: "Sei sicuro di voler lasciare '{{channelName}}'?",
    overlayDesc:
        'Qui puoi impostare la pertinenza minima che gli utenti devono avere per poter leggere i tuoi messaggi',
    judgmentOvelayDrag: 'Trascina per modificare',
    removeFriend: 'rimosso',
    groupInfo: 'GroupInfo',
    searchPlaceholder: 'Cerca...',
    sendInvite: 'Invia inviti al tuo gruppo',
    addFriend: 'aggiungi amico',
    rmFriend: 'non amico',
    myJudgment: 'Il mio giudizio',
    pollTitle: 'Nuovo sondaggio',
    pollQuestion: 'Domanda del sondaggio',
    enterPollQuestion: 'Inserisci la domanda del sondaggio',
    loading: 'caricamento',
    poll: 'sondaggio',
    byRelevance: '(per rilevanza)',
    votes: 'voti',
    noVotes: 'nessun voto',
    addOptionsCounter: 'Puoi aggiungere {{value}} più opzioni',
    addOption: "Aggiungi un'opzione",
    resultByRelevance: 'Mostra risultato per rilevanza',
    resultByNumberOfVotes: 'Mostra risultato per numero di voti',
    bigPoll: 'SONDAGGIO',
    enterAnswer: 'Inserisci risposta',
    vote: 'votare',
    voted: 'votato',
    errorPollInputs: 'Per favore, riempi tutti i campi',
    byRelevanceTitle: 'Per rilevanza',
    byNumberOfVotesTitle: 'Per numero di voti',
    showPoll: 'Mostra sondaggio "{{poll}}"',
    newAdmin: 'Nuovo amministratore',
    newAdminDescription: 'Vuoi impostare {{name}} come nuovo amministratore in "{{channel}}" chat?',
    system_message_admin_changed: '{{name}} è un nuovo amministratore',
    system_message_user_joined: '{{name}} si è unito alla chat',
    system_message_user_left: '{{name}} ha lasciato la chat',
    system_message_user_blocked: '{{name}} è stato bloccato nella chat',
    system_message_user_unblocked: '{{name}} è stato sbloccato nella chat',
    system_message_user_was_added: '{{name}} è stato aggiunto alla chat',
    type_group: 'Tipo di gruppo: {{groupType}}',
    group_is_private: "Solo l'amministratore della chat può invitare un nuovo partecipante",
    group_is_open: 'Qualsiasi utente della chat può invitare un nuovo partecipante',
    join_private_group: "Stai tentando di unirti al gruppo privato. Per favore, contatta l'amministratore del gruppo",
    voted_option_title: "Votato per l'opzione {{name}}",
    nobody_voted: "Nessuno ha votato per l'opzione",
    seconds: 'secondi',
    second: 'secondo',
    minutes: 'minuti',
    minute: 'minuto',
    hours: 'ore',
    hour: 'ora',
    one_member: 'membro',
    many_members: 'membri',
    country: 'paese',
    city: 'città',
    district: 'distretto',
    place: 'luogo',
    byWithProp: 'Da {{prop}}',
    enabled: 'abilitato',
    disabled: 'disabilitato',
    relevanceByLocation: 'Rilevanza per luogo: {{type}}',
};
