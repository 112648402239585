import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header/Header';
import useRootStore from '../../../hooks/useRootStore';
import styles from './GroupPolicy.module.css';
import Text from '../../../components/Text/Text';
import { FiUpload } from 'react-icons/fi';
import { ButtonComponent } from '../../../utils/button';
import { DownloadIcon } from '../../../utils/icons';
import { message } from 'antd';
const GroupPolicy = () => {
    const { t } = useTranslation();
    const { closeModal } = useRootStore().routerStore;
    const { channelData, setCreateChannelState, setCreateChannelData } = useRootStore().channelStore;

    const handleFileUpload = e => {
        closeModal('left');
    };

    const handleBack = () => {
        setCreateChannelState('privateGroupPolicyText', '');
        closeModal('left');
        message.info('Group Policy was not saved');
    };

    const handleSave = () => {
        message.info('Group Policy saved');
        closeModal('left');
    };

    return (
        <div>
            <Header
                text={t('group_policy')}
                colorPText={channelData.colorHighlight}
                leftIcon="arrowRight"
                onLeftIconPress={handleBack}
                textWeight={600}
            />
            <div className={styles.header}>
                {!channelData?.privateGroupPolicyFileLink ? (
                    <div className={styles.upload}>
                        <label htmlFor="upload" className={styles.uploadLabel}>
                            <Text fontSize="18" style={{ marginBottom: '10px' }}>
                                Upload group policy
                            </Text>
                            <FiUpload size={20} />
                        </label>
                        <input hidden id="upload" type="file" onChange={e => handleFileUpload(e)} />
                    </div>
                ) : (
                    <div className={styles.upload}>
                        <a href={channelData.privateGroupPolicyFileLink} className={styles.uploadLabel}>
                            <Text fontSize="18" style={{ marginBottom: '10px' }}>
                                Download group policy
                            </Text>
                            <DownloadIcon size={20} />
                        </a>
                    </div>
                )}
                <div className={styles.descriptionBox}>
                    <Text fontSize="18" style={{ marginBottom: '10px' }}>
                        Private Group Policy
                    </Text>
                    <textarea
                        disabled={!!channelData?.id}
                        className={styles.description}
                        rows={30}
                        placeholder="Private Group Policy"
                        value={setCreateChannelData.privateGroupPolicyText}
                        onChange={e => setCreateChannelState('privateGroupPolicyText', e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.footer}>
                <ButtonComponent
                    disabled={!setCreateChannelData.privateGroupPolicyText?.length}
                    width="100%"
                    margin="10px 0 0 0"
                    text="Save file/text"
                    clickMe={handleSave}
                />
            </div>
        </div>
    );
};

export default observer(GroupPolicy);
