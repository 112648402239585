import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FaHashtag, FaLink } from 'react-icons/fa';
import { FiUpload } from 'react-icons/fi';
import { IoIosColorPalette, IoLogoBuffer } from 'react-icons/io';
import { IoLogoTableau } from 'react-icons/io5';
import { MdBorderColor } from 'react-icons/md';
import Header from '../../../components/Header/Header';
import MenuItem from '../../../components/MenuItem/MenuItem';
import useRootStore from '../../../hooks/useRootStore';
import styles from './BrandGroup.module.css';
import NewInput from '../../../components/NewInput/NewInput';
import { ButtonComponent } from '../../../utils/button';
import { useState } from 'react';
import { message } from 'antd';
import ToolbarIcon from '../../../components/ToolbarIcon/ToolbarIcon';
import Colors from '../../../utils/colors';

const BrandGroupChat = () => {
    const { t } = useTranslation();
    const { setCreateChannelState, setCreateChannelData, readFile } = useRootStore().channelStore;
    const [hashtags, setHashtags] = useState(
        setCreateChannelData.defaultHashtags?.length ? setCreateChannelData.defaultHashtags?.join(', ') : '',
    );
    const { closeModal } = useRootStore().routerStore;

    const handleSave = () => {
        let newHashtags = hashtags?.split(' ').map(hash => {
            return '#' + hash.replaceAll('#', '');
        });
        setCreateChannelState('defaultHashtags', newHashtags);
        closeModal('left');
        message.info('Group Brand saved');
    };

    const handleBack = () => {
        setCreateChannelState('mainColor', '');
        setCreateChannelState('colorHighlight', '');
        setCreateChannelState('website', '');
        setCreateChannelState('defaultHashtags', []);
        setCreateChannelState('logo', '');
        setCreateChannelState('background', '');
        closeModal('left');
        message.info('Group Brand was not saved');
    };

    const handleHashtags = (e: string) => {
        setHashtags(e);
        // setCreateChannelState('defaultHashtags', e)
    };

    const uploadFile = (e: File, key: string) => {
        readFile(e, key);
    };

    return (
        <>
            <Header text={`${t('brandGroup')}`} textWeight={600} leftIcon="arrowLeft" onLeftIconPress={handleBack} />
            <div className={styles.settings}>
                <MenuItem
                    icon={<IoLogoTableau size={24} />}
                    title={`Logo  ${!!setCreateChannelData.logo?.length ? 'Uploaded' : ''}`}
                    right={
                        <ToolbarIcon accept="image/*" onChange={e => uploadFile(e, 'logo')}>
                            <FiUpload size={20} />
                        </ToolbarIcon>
                    }
                />

                <MenuItem
                    icon={<IoLogoBuffer size={24} />}
                    title={`Background  ${!!setCreateChannelData.background?.length ? 'Uploaded' : ''}`}
                    right={
                        <ToolbarIcon accept="image/*" onChange={e => uploadFile(e, 'background')}>
                            <FiUpload size={20} />
                        </ToolbarIcon>
                    }
                />
                <MenuItem
                    icon={<IoIosColorPalette size={24} />}
                    title={`Main Colour`}
                    right={
                        <input
                            value={setCreateChannelData.mainColor}
                            type="color"
                            className={styles.colorUser}
                            onChange={e => setCreateChannelState('mainColor', e.target.value)}
                        />
                    }
                />
                <MenuItem
                    icon={<MdBorderColor size={20} />}
                    title={`Colour Highlight`}
                    right={
                        <input
                            value={setCreateChannelData.colorHighlight}
                            type="color"
                            className={styles.colorUser}
                            onChange={e => setCreateChannelState('colorHighlight', e.target.value)}
                        />
                    }
                />
                <MenuItem icon={<FaLink size={18} />} title={`Website`} right={<></>} />
                <NewInput
                    onChange={e => setCreateChannelState('website', e)}
                    placeholder="www.example.com"
                    margin="0 15px"
                    width="93%"
                    fontSize="15px"
                    value={setCreateChannelData.website}
                />
                <MenuItem icon={<FaHashtag size={18} />} title={`Default Hashtags`} right={<></>} />
                <NewInput
                    onChange={e => handleHashtags(e)}
                    placeholder="#default #hashtag"
                    margin="0 15px"
                    width="93%"
                    fontSize="15px"
                    value={hashtags}
                />
            </div>
            <div className={styles.footer}>
                <ButtonComponent width="100%" margin="10px 0 0 0" text="Save" clickMe={handleSave} />
            </div>
        </>
    );
};

export default observer(BrandGroupChat);
