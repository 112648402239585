import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { formatMinutes, formatSeconds } from '../../../../../components/VoiceRecorder/format-time';
import useRecorder from '../../../../../components/VoiceRecorder/useRecorder';
import useRootStore from '../../../../../hooks/useRootStore';
import {
    ArrowDowunIcon,
    ArrowUpIcon,
    CloserNoCirculIcon,
    DeleteIcon,
    FillterIcon,
    MicrophoneIcon,
    SendIcon,
} from '../../../../../utils/icons';
import FilterToolbar from '../filterToolbar/FilterToolBar';
import FooterToolbarView from '../footerToolbar/FooterToolBar';
import styles from './index.module.css';
import ReplyMessage from '../replyMessage/replyMessageComponent';
import AddHashtags from '../../../../../components/AddHashtags/addhashtags';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

interface User {
    id: number;
    username: string;
}

function MessageInput() {
    const navigate = useNavigate();
    const { name } = useParams();
    const { recorderState, ...handlers } = useRecorder();
    const { visible, toglevisible } = useRootStore().visibleStore;
    const { channelData, getSlectedChannelUsers } = useRootStore().channelStore;
    const { setOneToOne, setIsOpenTagScreen, clearTagMessages } = useRootStore().channelSocketStore;
    const { user } = useRootStore().authStore;
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [openHashtag, setOpenHashtag] = useState<boolean>(false);
    const [showUserList, setShowUserList] = useState<boolean>(false);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [cursorPosition, setCursorPosition] = useState<number>(0);
    const { setMessageText, messageTextState, onSendMessage, addHashtag } = useRootStore().messageStore;

    const handleOnSendMessage = () => {
        if (messageTextState) {
            onSendMessage('text');
            setMessageText('');
            setShowUserList(false);
        }
    };

    useEffect(() => {
        var textarea: any = document.getElementById('textarea');
        textarea.oninput = function () {
            textarea.style.height = '';
            textarea.style.height = textarea.scrollHeight + 'px';
        };
    }, []);

    useEffect(() => {
        setMessageText('');
    }, [navigate]);

    const onSendEnter = (e: any) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleOnSendMessage();
            e.preventDefault();
        }
    };

    const markSelectedChannelUsers = () => {
        const selectedUsers = toJS(getSlectedChannelUsers);
        const usersArray = Object.values(selectedUsers)
            .map(user => ({
                id: user.id,
                username: user.username,
            }))
            .filter(userT => userT.id !== user.id) as User[];

        const usernames = usersArray.map(user => ({ username: user.username, id: user.id }));
        // const markedUsernames = usernames.map(username => `${username.username}`);
        return usernames;
    };

    const handleTextareaChange = (e: any) => {
        const { value, selectionStart } = e.target;
        setMessageText(value);
        setCursorPosition(selectionStart);

        const startWord = value.substring(0, selectionStart).split(' ').pop();
        if (startWord.startsWith('@')) {
            const query = startWord.substring(1).toLowerCase();
            const filtered = markSelectedChannelUsers().filter(username =>
                username.username.toLowerCase().startsWith(query),
            );
            clearTagMessages();
            setFilteredUsers(filtered);
            setShowUserList(true);
            console.log(filtered);
        } else {
            setShowUserList(false);
        }
    };

    const handleUserClick = (user: User) => {
        const value = messageTextState;
        const start = value.substring(0, cursorPosition).lastIndexOf('@');
        const newText = value.substring(0, start + 1) + user.username + ' ' + value.substring(cursorPosition);
        setMessageText(newText);
        setShowUserList(false);
        console.log('selected', toJS(user));
        setOneToOne({ id: user.id, username: user.username });
        setIsOpenTagScreen(true);

        navigate(
            generatePath('/:name', {
                name: name || '',
            }) +
                generatePath('/chatat/:chatAt', {
                    chatAt: user.username,
                }),
        );
    };

    return (
        <MessageInputContainer>
            <div className="container">
                <ReplyMessage />
                <FilterToolbar isOpen={openFilter} />
                <AddHashtags isOpen={openHashtag} setopenhashtags={setOpenHashtag} />
                <FooterToolbarView
                    props={visible.openFooterMediaBar}
                    openHashTags={openHashtag}
                    setOpenHashtags={setOpenHashtag}
                    isPremium={channelData.isPremium}
                    colorHighlight={channelData.colorHighlight}
                />
                <div className={styles.inputmessage}>
                    {!visible.openFooterMediaBar ? (
                        <button className="icon" onClick={() => toglevisible('openFooterMediaBar')}>
                            <ArrowUpIcon color={channelData.isPremium ? channelData.colorHighlight : '#303030'} />
                        </button>
                    ) : (
                        <button className="icon" onClick={() => toglevisible('openFooterMediaBar')}>
                            <ArrowDowunIcon color={channelData.isPremium ? channelData.colorHighlight : '#303030'} />
                        </button>
                    )}
                    <button className="icon" onClick={() => setOpenFilter(!openFilter)}>
                        {!openFilter ? (
                            <FillterIcon
                                size={17}
                                color={channelData.isPremium ? channelData.colorHighlight : '#303030'}
                            />
                        ) : (
                            <CloserNoCirculIcon
                                size={17}
                                color={channelData.isPremium ? channelData.colorHighlight : '#303030'}
                            />
                        )}
                    </button>
                    <div className="inputContainer">
                        <textarea
                            id="textarea"
                            placeholder={
                                recorderState.initRecording
                                    ? `${formatMinutes(
                                          recorderState.recordingMinutes,
                                      )} : ${formatSeconds(recorderState.recordingSeconds)}`
                                    : addHashtag?.id
                                      ? 'Add a hashtag starting with #'
                                      : 'Write a message...'
                            }
                            value={messageTextState}
                            onKeyDown={e => onSendEnter(e)}
                            autoFocus
                            disabled={recorderState.initRecording}
                            onChange={handleTextareaChange}
                            className="textAreaInput"
                        />
                        {showUserList && (
                            <UserList>
                                {filteredUsers.map((user, _index) => (
                                    <UserListItem key={_index.toString()} onClick={() => handleUserClick(user)}>
                                        {user.username}
                                    </UserListItem>
                                ))}
                            </UserList>
                        )}
                    </div>
                    {messageTextState || addHashtag?.id ? (
                        <button className="icon" onClick={handleOnSendMessage}>
                            <SendIcon color={channelData.isPremium ? channelData.colorHighlight : '#303030'} />
                        </button>
                    ) : recorderState.initRecording ? (
                        <div className="iconBox">
                            <button className={`icon`} onClick={handlers.cancelRecording}>
                                <DeleteIcon color="#e74c3c" />
                            </button>
                            <button className="icon" onClick={handlers.saveRecording}>
                                <SendIcon color={channelData.isPremium ? channelData.colorHighlight : '#303030'} />
                            </button>
                        </div>
                    ) : (
                        <button className="icon" onClick={handlers.startRecording}>
                            <MicrophoneIcon color={channelData.isPremium ? channelData.colorHighlight : '#303030'} />
                        </button>
                    )}
                </div>
            </div>
        </MessageInputContainer>
    );
}

export default observer(MessageInput);

const MessageInputContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(25px);
    box-shadow:
        0px -8px 48px 0px rgba(32, 35, 39, 0.02),
        0px -4px 8px 0px rgba(32, 35, 39, 0.04),
        0px 0px 1px 0px rgba(32, 35, 39, 0.16);
    z-index: 15;

    .container {
        position: relative;
        width: 100%;
        height: auto;
        padding: 6px 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        transition:
            height 0.15s ease-out,
            opacity 0.15s ease-out;
    }

    .inputContainer {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        margin: 0 5px 0 5px;
        border: 0.5px solid #a3aea6;
        border-radius: 8px;

        .textAreaInput {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 15px;
            width: 100%;
            height: 35px;
            padding: 7.5px 15px;
            line-height: 20px;
            border: none;
            color: #a3aea6;
            outline: none;
            resize: none;
            opacity: initial;
            overflow: hidden;
            background-color: transparent;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        outline: none;
        border: none;
        background-color: transparent;
    }

    .iconBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
    }
`;

const UserList = styled.ul`
    position: absolute;
    bottom: 40px;
    width: 100%;
    background: white;
    box-shadow:
        0px 8px 48px 0px rgba(32, 35, 39, 0.1),
        0px 4px 8px 0px rgba(32, 35, 39, 0.1),
        0px 0px 1px 0px rgba(32, 35, 39, 0.16);
    border-radius: 8px;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 10;
`;

const UserListItem = styled.li`
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }
`;
