// const url1 = 'http://localhost:4001';
const url1 = 'https://e-camp.uz';

export const TMP_URL = url1;
export const clientId = '264166265317-e4ah3ndn036obo4ti9jr4hc5dmjn3m6j.apps.googleusercontent.com';

// export const clientId =
//     "785700875784-npn39s33m53tu1ecmq7anbcp8vidf75k.apps.googleusercontent.com";
export const Env = {
    ApiUrl: `${TMP_URL}/api/`,
    SocketUrl: TMP_URL,
    AssetsUrl: `${TMP_URL}`,
};

export const project_version = '1.3.3v';
