import React from 'react';
import MessageAudio from '../../../../components/Chat/MessageAudio';
import { RawMessage } from '../../../../types/channel';
import styles from '../GroupMedia.module.css';
import Text from '../../../../components/Text/Text';

type PropsList = {
    data: RawMessage[];
};

const AudioList: React.FC<PropsList> = ({ data }) => {
    return (
        <div className={styles.audioList}>
            {data.length === 0 && (
                <div style={{ width: '100%' }}>
                    <Text fontSize="14px" center>
                        No Audio
                    </Text>
                </div>
            )}
            {data.map(audio => {
                return (
                    <div key={audio.id} className={styles.audioItem}>
                        <MessageAudio message={audio} users={undefined} position={undefined} />
                    </div>
                );
            })}
        </div>
    );
};

export default AudioList;
