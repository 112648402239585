import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import MessageComponent from '../../../components/Chat/MessageComponent/MessageComponent';
import ModalToPrivateChannel from '../../../components/ModalToPrivateChannel';
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer';
import useRootStore from '../../../hooks/useRootStore';
import ChatHeader from '../../../utils/chatHeader';
import MessageInput from './components/messageInput/MessageInput';

const Chat = () => {
    const navigate = useNavigate();
    const { messagesFilterValue, setIsMessagesLength, getSelectedChannelMsgs, searchMessages } =
        useRootStore().messageStore;
    const { closeRightSideBar } = useRootStore().routerStore;
    const { getSlectedChannelUsers, selectedChannelData } = useRootStore().channelStore;
    const { searchMessageId, setChannelSlug, exitChannelEmit } = useRootStore().messageStore;

    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                exitChannelEmit();
                navigate('/');
                closeRightSideBar();
                setChannelSlug('');
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [closeRightSideBar, navigate]);

    useEffect(() => {
        if (searchMessageId) {
            const element = document.getElementById(searchMessageId);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [searchMessageId]);

    const messagesV2: React.ReactNode[] = useMemo(() => {
        const messagesData = getSelectedChannelMsgs;

        if (!messagesData || messagesData.length === 0) {
            setIsMessagesLength(true);
            return [];
        }

        setIsMessagesLength(false);

        const filteredMessages = messagesData.filter(
            msg => msg.relevance !== undefined && msg.relevance >= messagesFilterValue,
        );

        const messageComponents: React.ReactNode[] = [];

        for (let i = 0; i < filteredMessages.length; i++) {
            const msg = filteredMessages[i];
            messageComponents.unshift(
                <MessageComponent
                    key={msg.id}
                    isFirst={msg === filteredMessages[0]}
                    isLast={filteredMessages.length - 1 === i}
                    _ref={ref => {}}
                    message={msg}
                    users={getSlectedChannelUsers}
                    isSearchMessage={
                        (!!searchMessages?.count &&
                            !!searchMessages.messages.find(SMessage => SMessage.id === msg.id)?.id) ??
                        false
                    }
                />,
            );
        }

        return messageComponents;
    }, [
        getSelectedChannelMsgs,
        messagesFilterValue,
        getSlectedChannelUsers,
        setIsMessagesLength,
        searchMessages.count,
    ]);

    return (
        <ChatContainer id="chatView">
            <ChatHeader />
            <ScrollContainer>{messagesV2}</ScrollContainer>
            <MessageInput />
            <ModalToPrivateChannel />
        </ChatContainer>
    );
};

export default observer(Chat);

const ChatContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;
