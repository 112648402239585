import { Tag, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { Env } from '../../../env';
import { extractHourMinute } from '../../../helper/helper';
import useRootStore from '../../../hooks/useRootStore';
import { ChannelsUsersType, RawMessage } from '../../../types/channel';
import { relevanceFuniction } from '../../../utils/boxShadov';
import Colors from '../../../utils/colors';
import SmallAvatar from '../../SmallAvatar/smallAvatar';
import Text from '../../Text/Text';
import { RenderMessage } from '../AllMessage/AllMessage';
import DropDownMenu from '../DropDownMenu/dropdownmenu';
import MessageBox from '../MessageBox';
import MessageHeader from '../MessageHeader';
import { ReplyTypeRender } from '../ReplyedMessage/beforeSendReply';
import { MediaTitleText } from '../../../utils/MediaTitleText';
import { toJS } from 'mobx';

interface User {
    id: number;
    username: string;
}

interface Props {
    message: RawMessage;
    users: {
        [key: string]: ChannelsUsersType;
    };
    isFirst: boolean;
    isLast: boolean;
    isSearchMessage?: boolean;
    _ref: (node: HTMLDivElement | null) => void;
}

const MessageComponent: FC<Props> = ({ message, users, isFirst, isLast, _ref, isSearchMessage = false }) => {
    const navigate = useNavigate();
    const { name } = useParams();
    const messageRef = useRef<HTMLDivElement>(null);
    const { isOpenHashTagScreen, setHashTags, enter } = useRootStore().hashtagStore;
    const { openInUser } = useRootStore().routerStore;
    const { getFriendDetails } = useRootStore().usersStore;
    const { user } = useRootStore().authStore;
    const { channelData } = useRootStore().channelStore;
    const { setOneToOne, setIsOpenTagScreen } = useRootStore().channelSocketStore;

    const [pimp, setPimp] = useState<
        | {
              pimpType: 'pimp' | 'unPimp';
              value: number | undefined;
          }
        | undefined
    >(undefined);

    useEffect(() => {
        if (_ref) _ref(messageRef.current);
    }, [_ref]);

    const msg = useMemo(() => {
        return { ...message, relevance: JSON.parse(message.pimps)[user.id] ?? message.relevance };
    }, [message]);
    const MESSAGE_STYLE = useMemo(() => relevanceFuniction(msg), [msg]);
    const boxShadov = MESSAGE_STYLE?.boxShadow;
    const currentUser: ChannelsUsersType | undefined = users?.[message.userId];
    const position = useMemo(() => msg.userId === user.id, [user, msg]);

    const handleHashTagClick = (tag: string) => {
        if (isOpenHashTagScreen) {
            setHashTags(tag);
            enter();
        } else {
            setHashTags(tag);
            enter();
            navigate(
                generatePath('/:name', {
                    name: name || '',
                }) +
                    generatePath('/:hashtag', {
                        hashtag: tag,
                    }),
            );
        }
    };

    const openUserAccount = () => {
        if (user.id === currentUser?.id) return;
        if (msg.userId === -1) {
            if (msg.message.includes('is')) {
                let user_id = channelData.users.find(user => user.username === msg.message.split(' is')[0])?.id;
                !!user_id && getFriendDetails(user_id);
                user_id && openInUser();
            } else if (msg.message.includes('was')) {
                let user_id = channelData.users.find(user => user.username === msg.message.split(' was')[0])?.id;
                !!user_id && getFriendDetails(user_id);
                user_id && openInUser();
            }
        } else {
            getFriendDetails(currentUser?.id);
            openInUser();
        }
    };

    const handleUserClick = (user: User) => {
        console.log('selected', toJS(user));
        setOneToOne({ id: user.id, username: user.username });
        setIsOpenTagScreen(true);
        navigate(
            generatePath('/:name', {
                name: name || '',
            }) +
                generatePath('/chatat/:chatAt', {
                    chatAt: user.username,
                }),
        );
    };

    const renderMessage = useMemo(
        () => <RenderMessage message={msg} isSearchMessage={isSearchMessage} />,
        [msg, isSearchMessage],
    );

    if (msg.userId === -1) {
        return (
            <MessageBoxContainer $isFirst={isFirst} $isLast={isLast} ref={messageRef}>
                <MessageBox text={msg.message} own={0} titleOnPress={openUserAccount} />
            </MessageBoxContainer>
        );
    }

    return (
        <MessageBoxContainer id={msg.id} $isFirst={isFirst} $isLast={isLast} ref={messageRef}>
            <Container $position={position} $isRaplayed={msg.isReply}>
                <MessageAvatar $position={position}>
                    <SmallAvatar
                        forMessage
                        color={currentUser?.color || msg.color}
                        imageUrl={currentUser?.avatar && `${Env.AssetsUrl}/${currentUser?.avatar}`}
                        onPress={openUserAccount}
                    />
                </MessageAvatar>
                <BoxShadow $boxShodow={boxShadov}>
                    <MessageHeader
                        name={msg.username}
                        color={currentUser?.color || msg.color}
                        userId={msg.userId}
                        message={msg}
                        setPimp={setPimp}
                        titleOnPress={openUserAccount}
                        isDelete={!currentUser?.id}
                    >
                        <MessageContainer>
                            <DropDownMenu massage={msg}>
                                <AudioPlayContainer>
                                    {msg.isReply && (
                                        <div className="replayMessage">
                                            <MessageHeader
                                                name={msg.originMessage?.username}
                                                showReply
                                                color={msg?.originMessage?.color}
                                                style={{
                                                    fontFamily: 'sans-serif',
                                                    fontSize: '20px',
                                                }}
                                                message={msg?.originMessage as RawMessage}
                                            />
                                            <div className="messageMain">{ReplyTypeRender(msg)}</div>
                                        </div>
                                    )}
                                    {!!msg.taggedUserId && (
                                        <MessageTaggedUser
                                            onClick={() =>
                                                handleUserClick({
                                                    username:
                                                        channelData.users.find(user => user.id == msg.taggedUserId)
                                                            ?.username ?? '',
                                                    id: msg.taggedUserId,
                                                })
                                            }
                                        >
                                            <Text>
                                                @{channelData.users.find(user => user.id == msg.taggedUserId)?.username}
                                            </Text>
                                        </MessageTaggedUser>
                                    )}
                                    {renderMessage}
                                    {msg.message?.length && msg.type === 'image' && (
                                        <MediaTitleContainer>
                                            <Text fontSize="14px" fontWeight={600}>
                                                {msg.message}
                                            </Text>
                                        </MediaTitleContainer>
                                    )}
                                    {message.type === 'audio' && (
                                        <MediaAudioTitleContainer $color={'#' + msg?.color?.replaceAll('#', '')}>
                                            <MediaTitleText mediaTitle={message.mediaTitle ?? ''} />
                                        </MediaAudioTitleContainer>
                                    )}
                                    <MessageFooter>
                                        <HashTagsContainer>
                                            {msg.hashtags &&
                                                msg.hashtags.map((tag, index) => {
                                                    const isLongTag = tag.length > 20;
                                                    const tagElem = (
                                                        <Tag key={index} onClick={() => handleHashTagClick(tag)}>
                                                            <Text
                                                                fontSize="12px"
                                                                fontFamily="Montserrat"
                                                                fontWeight={600}
                                                                margin="0"
                                                            >
                                                                #
                                                                {isLongTag
                                                                    ? `${tag.slice(0, 20).replaceAll('#', '')}...`
                                                                    : tag.replaceAll('#', '')}
                                                            </Text>
                                                        </Tag>
                                                    );
                                                    return isLongTag ? (
                                                        <Tooltip title={tag} key={tag}>
                                                            {tagElem}
                                                        </Tooltip>
                                                    ) : (
                                                        tagElem
                                                    );
                                                })}
                                        </HashTagsContainer>

                                        <TimeViewContainer>
                                            {message.isEdit && <MdEdit color={Colors.GullGray} size={16} />}
                                            <Text
                                                fontSize="10px"
                                                fontWeight={500}
                                                style={{
                                                    textAlign: 'end',
                                                }}
                                            >
                                                {extractHourMinute(msg.timestamp)}
                                            </Text>
                                        </TimeViewContainer>
                                    </MessageFooter>
                                </AudioPlayContainer>
                            </DropDownMenu>
                        </MessageContainer>
                    </MessageHeader>
                </BoxShadow>
            </Container>
        </MessageBoxContainer>
    );
};

export default observer(MessageComponent);

const MessageBoxContainer = styled.div<{ $isFirst: boolean; $isLast: boolean }>`
    padding-bottom: ${({ $isFirst }) => ($isFirst ? '7.5vh' : '0')};
    padding-top: ${({ $isLast }) => ($isLast ? '7vh' : '0')};
    scroll-padding-top: 400px;
`;

const Container = styled.div<{ $isRaplayed?: boolean; $position?: boolean }>`
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: ${({ $position }) => ($position ? 'flex-end' : 'flex-start')};
`;

const MessageContainer = styled.div`
    width: 100%;
    height: 100%;

    .avatarCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .replayMessage {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 10px;
        margin-top: 5px;
        gap: 5px;
        cursor: pointer;

        .messageMain {
            margin-bottom: 5px;
        }
    }
`;

const AudioPlayContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
    background-color: #fff;
    z-index: 1;
`;

const BoxShadow = styled.div<{ $boxShodow?: string }>`
    width: fit-content;
    position: relative;
    height: 100%;
    border-radius: 15px;
    box-shadow: ${({ $boxShodow }) => $boxShodow || 'none'};
    z-index: auto;
`;

const MessageFooter = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: auto 30px;
    padding: 0 15px;
    margin-bottom: 10px;
    margin-top: 5px;
`;

const HashTagsContainer = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    flex-wrap: wrap;
`;

const TimeViewContainer = styled.div`
    align-self: flex-end;
    justify-self: flex-end;
    display: flex;
    gap: 8px;
`;

const MediaTitleContainer = styled.div`
    width: 100%;
    max-width: 450px;
    padding: 0 10px;
    height: auto;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
`;

const MessageTaggedUser = styled.div`
    width: 100%;
    padding: 0 10px;
    cursor: pointer;
    p {
        font-style: italic;
        font-weight: 600;
    }
`;

const MediaAudioTitleContainer = styled.div<{ $color?: string }>`
    width: 100%;
    max-width: 350px;
    padding: 0 10px;
    height: auto;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    div {
        background-color: ${({ $color }) => $color + '29'} !important;
        padding: 3px;
        border-left: 2px solid ${({ $color }) => $color};
        border-radius: 5px;
    }

    .dot-ellipsis {
        margin-left: 2px;
    }

    .dot-ellipsis span {
        animation: dot-blink 1.4s infinite steps(3, start);
    }

    .dot-ellipsis span:nth-child(2) {
        animation-delay: 0.2s;
    }

    .dot-ellipsis span:nth-child(3) {
        animation-delay: 0.4s;
    }

    @keyframes dot-blink {
        0%,
        20% {
            color: rgba(0, 0, 0, 0);
        }
        50%,
        100% {
            color: #333;
        }
    }
`;

const MessageAvatar = styled.div<{ $position?: boolean }>`
    display: ${({ $position }) => ($position ? 'none' : 'flex')};
    align-self: flex-end;
    z-index: 2;
`;
