export const pt = {
    welcome_text: 'Bem-vindo ao ChatXO',
    login: 'Login',
    loginScreen: 'Login com e-mail',
    create_account: 'criar_conta',
    change_language: 'Línguas',
    english_language: 'inglês',
    german_language: 'alemão',
    french_language: 'francês',
    italian_language: 'Italiano',
    spanish_language: 'espanhol',
    portuguese_language: 'português',
    username: 'Nome de Utilizador Único',
    your_email: 'O teu_email',
    password: 'Palavra-passe',
    your_password: 'A sua palavra-passe',
    sign_up: 'Sign Up',
    sign_up_header: 'Criar uma conta',
    sign_up_email: 'Regístrese con el correo electrónico',
    sign_up_header_username: 'Introduzca un nombre de usuario único',
    groups: 'Grupos',
    group: 'grupo',
    no_avalible_groups: 'Nenhum grupo disponível',
    'private_group ': 'Grupo_privado',
    friends: 'Amigos',
    friend: 'amigo',
    filter: 'Filtrar',
    add_friends: 'Acrescentar amigos',
    add: 'Acrescentar',
    added: 'Acrescentado',
    add_to_channel: 'Quer adicionar {{{nome_do_canal}} a {{nome_do_canal}}?',
    delete_from_channel: 'Deseja remover {{{nome_de_canal}} de {{{nome_do_canal}}?',
    invite: 'Convidar',
    discover: 'Descobrir',
    create_channel: 'criar_grupo',
    edit_profile: 'Editar perfil',
    group_name: 'Nome_grupo',
    invite_friends: 'Convidar_amigos',
    no_avalible_friends: 'Sem amigos disponíveis',
    create: 'criar',
    create_channel_req: 'Nome do grupo é obrigatório',
    edit_channel_name: 'Editar nome do grupo',
    save: 'Salvar',
    select_image: 'Seleccionar imagem',
    random_color: 'Cor aleatória',
    leave_group: 'Leave group',
    add_hashtag: 'Acrescentar hashtag',
    done: 'Feito',
    take_photo: 'Tirar_foto',
    photo_gallery: 'Escolher fotografia da galeria',
    video_gallery: 'Escolher vídeo da galeria',
    choose_document: 'Escolher documento',
    audio: 'Escolher áudio',
    cancel: 'Cancelar',
    download: 'Descarregar',
    play: 'Brincar',
    pause: 'Pausa',
    media_player: 'media_player',
    account: 'conta',
    settings: 'Definições',
    relevance: 'O meu julgamento',
    in_channels: 'em grupos',
    per_user: 'por_utilizador',
    empty_rel_channles: 'Sem relevância por grupos',
    empty_rel_users: 'Sem relevância por amigos',
    no_avalible_channels: 'Nenhum grupo disponível',
    'unfriend ': 'Não-Amigo',
    press_to_change: 'pressione para mudar',
    error_message_username_unique: 'nome_de_utilizador não é único',
    error_message_username_req: 'nome_de_utilizador é obrigatório',
    error_message_username_min: 'Nome de utilizador deve ter pelo menos 3 caracteres',
    error_message_username_regex: 'nome de utilizador não pode ser uma combinação de caracteres e dígitos especiais',
    error_message_email_req: 'Email é necessário',
    error_message_email_form: 'formato de correio electrónico inválido',
    error_message_password_regex:
        'A palavra-passe deve ser uma combinação de letras maiúsculas e minúsculas, dígitos e caracteres especiais',
    error_message_password_min: 'A palavra-passe deve ter pelo menos 8 caracteres',
    error_message_password_req: 'Password is required',
    error_message_groupName_req: 'O primeiro símbolo não pode ser um espaço',
    error_type: 'formato GIF não é permitido',
    update_profile: 'Actualize o seu perfil',
    update_relevance: 'Update judgement',
    image_picker_error: 'Erro do imagePicke',
    join: 'Joining...',
    join_group: 'Join group',
    file_size_error: 'Este ficheiro não pode ser carregado',
    file_size_error_big: 'O ficheiro é demasiado grande',
    unknown_error: 'Erro Desconhecido',
    no_discover_channels: 'Não há novos grupos a descobrir',
    error: 'Erro',
    permissions_storage_android: 'ChatXO precisa de acesso ao seu armazenamento',
    permissions_storage_write_android_message:
        'A aplicação necessita de acesso ao seu armazenamento de ficheiros para que possa descarregar o ficheiro',
    no_permissions: 'Permissão não concedida',
    how_to_enable: 'Ir para definições para permitir o armazenamento do ficheiro',
    how_to_enable_microphone: 'Ir para definições para activar o microfone',
    permissions_storage_read_android_message:
        'O aplicativo necessita de acesso ao seu armazenamento de ficheiros para que possa reproduzir o ficheiro',
    permissions_microphone_android: 'ChatXO precisa de acesso ao seu microfone',
    permissions_microphone_android_message:
        'A aplicação necessita de acesso ao seu microfone para que possa gravar um ficheiro',
    type_message: 'Escreva uma mensagem...',
    relevance_in: 'Relevância em',
    delete: 'Eliminar',
    reply: 'Relatório',
    copy: 'Cópia',
    pinMessage: 'PinMessage',
    unpinMessage: 'Unpin message',
    reply1: 'Responder',
    reportMessage: 'Mensagem foi relatada com sucesso',
    remover_imagem: 'remover_imagem',
    ok: 'ok',
    take_video: 'Gravar um vídeo',
    max_username_chars: 'O nome de usuário deve ter no máximo 20 caracteres',
    hashtag_already_exists: 'Hashtag já existe',
    press_to_record: 'Pressione e segure para gravar',
    signUpSucc_title: 'Conta criada com sucesso',
    signUpSucc_message: 'Obrigado por seu registro! Sua conta está pronta para ser usada.',
    personal_relevance: 'Relevância pessoal',
    go_to_profile: 'Ir para o perfil',
    no_longer_in_group: 'O usuário não está mais no grupo',
    invited: 'Convidado',
    groupsNumber: 'Número do grupo',
    or: 'ou',
    scanner: 'scanner',
    groupsInvite: 'Senha do grupo',
    groupsName: 'Nome do grupo',
    inviteUsers: 'Convidar usuários',
    createGroup: 'Criar novo grupo',
    generateNew: 'Gerar Novo',
    noCode: 'NoCode',
    groupsPassword: 'Senha',
    noPassword: 'Sem senha',
    editGroup: 'Editar grupo',
    blockedUsers: 'Usuários bloqueados',
    no_blocked_users: 'Nenhum usuário bloqueado',
    block_user_dialog: 'Você deseja bloquear {{username}} em {{channelName}}?',
    unblock_user_dialog: 'Deseja desbloquear {{username}} em {{channelName}}?',
    block_user_button: 'Bloquear usuário',
    unblock_user_button: 'Desbloquear',
    blocked_user_anuthorized: 'Você foi bloqueado no grupo. Por favor, entre em contato com o administrador',
    manageUsers: 'Gerenciar usuários',
    deleteGroup: 'Excluir grupo',
    update: 'atualizar',
    addParticipante: 'AdicionarParticipante',
    shareGroup: 'Compartilhe seu grupo',
    complete: 'completo',
    link: 'ligação',
    copyLink: 'Copiar link de bate-papo',
    share: 'Compartilhar bate-papo',
    enterName: 'Digite um nome',
    uploadPhoto: 'Carregar foto',
    linkCopied: 'Link copiado com sucesso',
    msgCopied: 'A mensagem foi copiada',
    login_fb: 'Continuar com o Facebook',
    login_google: 'Continuar com o Google',
    login_apple: 'Continuar com a Apple',
    web_err_title: 'Erro',
    web_err_msg: 'Erro ao carregar página da web',
    forgot_pass: 'Esqueci a senha',
    forgot_text: 'Esqueci a senha',
    reset_btn: 'Enviar',
    success_alert_title: 'Sucesso',
    reset_alert_msg: 'Verifique seu email, haverá uma nova senha.',
    error_alert_title: 'Erro',
    apple_auth_err_msg: 'Erro ao gerar token',
    or_social_auth: 'ou',
    error_google_auth_play_service: 'Erro no Google Play Services',
    error_google_auth_other: 'Erro',
    error_fb_auth: 'Erro',
    error_fb_auth_get_user: 'Ocorreu um erro ao obter informações do usuário',
    error_channel_404: 'Este canal ainda não existe',
    error_toast: 'Não encontrado',
    option_search: 'Pesquisar',
    option_groupInfo: 'Informações do grupo',
    option_reportChat: 'Denunciar bate-papo',
    option_leaveChat: 'Sair do grupo',
    delete_from_friends: 'Deseja remover {{username}} dos amigos?',
    leave_title: 'Sair do grupo',
    leave_text: "Tem certeza de que deseja sair de '{{channelName}}'?",
    overlayDesc:
        'Aqui você pode definir a relevância mínima que os usuários precisam ter para poder ler suas mensagens',
    judgmentOvelayDrag: 'Arraste para alterar',
    removeFriend: 'removido',
    groupInfo: 'GroupInfo',
    searchPlaceholder: 'Pesquisar...',
    sendInvite: 'Enviar convites para seu grupo',
    addFriend: 'adicionar amigo',
    rmFriend: 'não amigo',
    myJudgment: 'Meu julgamento',
    pollTitle: 'Nova enquete',
    pollQuestion: 'Pergunta da enquete',
    enterPollQuestion: 'Digite a pergunta da enquete',
    loading: 'carregando',
    poll: 'enquete',
    byRelevance: '(por relevância)',
    votes: 'votos',
    noVotes: 'sem votos',
    addOptionsCounter: 'Você pode adicionar {{value}} mais opções',
    addOption: 'Adicionar uma opção',
    resultByRelevance: 'Mostrar resultado por relevância',
    resultByNumberOfVotes: 'Mostrar resultado por número de votos',
    bigPoll: 'PESQUISA',
    enterAnswer: 'Digite a resposta',
    vote: 'voto',
    voted: 'votado',
    errorPollInputs: 'Por favor, preencha todos os campos',
    byRelevanceTitle: 'Por relevância',
    byNumberOfVotesTitle: 'Por número de votos',
    showPoll: 'Mostrar enquete "{{enquete}}"',
    newAdmin: 'Novo administrador',
    newAdminDescription: 'Você deseja definir {{name}} como um novo administrador em "{{channel}}" chat?',
    system_message_admin_changed: '{{name}} é um novo administrador',
    system_message_user_joined: '{{name}} entrou no chat',
    system_message_user_left: '{{name}} saiu do chat',
    system_message_user_blocked: '{{name}} foi bloqueado no chat',
    system_message_user_unblocked: '{{name}} foi desbloqueado no chat',
    system_message_user_was_added: '{{name}} foi adicionado ao chat',
    type_group: 'Tipo de Grupo: {{groupType}}',
    group_is_private: 'Somente o administrador do chat pode convidar um novo participante',
    group_is_open: 'Qualquer usuário do chat pode convidar um novo participante',
    join_private_group:
        'Você está tentando entrar no grupo Privado. Por favor, entre em contato com o Administrador do grupo',
    voted_option_title: 'Voto na opção {{name}}',
    nobody_voted: 'Ninguém votou na opção',
    seconds: 'segundos',
    second: 'segundo',
    minutes: 'minutos',
    minute: 'minuto',
    hours: 'horas',
    hour: 'hora',
    one_member: 'membro',
    many_members: 'membros',
    country: 'país',
    city: 'cidade',
    district: 'distrito',
    place: 'lugar',
    byWithProp: 'Por {{prop}}',
    enabled: 'activado',
    disabled: 'deficientes',
    relevanceByLocation: 'Relevância por localização: {{type}}',
};
