import { useEffect } from 'react';
import RootRouter from './router/RootRouter';

function App() {
    useEffect(() => {
        const handleTabPress = event => {
            if (event.key === 'Tab') {
                event.preventDefault();
                const focusableElements = document.querySelectorAll('input, select, textarea, button');
                const firstElement: any = focusableElements[0];
                const lastElement: any = focusableElements[focusableElements.length - 1];

                if (event.shiftKey) {
                    if (document.activeElement === firstElement) {
                        lastElement.focus();
                        event.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastElement) {
                        firstElement.focus();
                        event.preventDefault();
                    }
                }
            }
        };

        document.addEventListener('keydown', handleTabPress);

        return () => {
            document.removeEventListener('keydown', handleTabPress);
        };
    }, []);

    return (
        <>
            <RootRouter />
        </>
    );
}

export default App;
