import { message, Slider, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLayerGroup } from 'react-icons/fa';
import { HiClipboardDocumentCheck } from 'react-icons/hi2';
import { IoMdFunnel } from 'react-icons/io';
import { IoColorFilter } from 'react-icons/io5';
import { MdGroup } from 'react-icons/md';
import { PiSlidersHorizontalBold } from 'react-icons/pi';
import { generatePath, useNavigate } from 'react-router-dom';
import AvatarUpload from '../../../components/AvatarUpload/AvatarUpload';
import Header from '../../../components/Header/Header';
import MenuItem from '../../../components/MenuItem/MenuItem';
import NewInput from '../../../components/NewInput/NewInput';
import useRootStore from '../../../hooks/useRootStore';
import { ButtonComponent } from '../../../utils/button';
import styles from './CreateChannel.module.css';
import Input from '../../../components/Input';

const CreateChannel = () => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const { closeModal, openRightSideBar, toRouterManageCh, toRouter } = useRootStore().routerStore;
    const { createAvatar, createChannel, setCreateChannelState, setCreateChannelData, setCropAvatarState } =
        useRootStore().channelStore;
    const { visible, toglevisible, show } = useRootStore().visibleStore;

    const isPrivateGroup = (checked: boolean) => {
        setCreateChannelState('isPrivate', checked);
    };
    const isPremiumGroup = (checked: boolean) => {
        setCreateChannelState('isPremium', checked);
    };

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setCropAvatarState(e.target.files[0], 'crateChannel');
            show('chUploadFile');
        }
    };

    const callbackHandle = e => {
        const target = e =>
            generatePath(`/:name`, {
                name: `@${e}`,
            });
        closeModal('left');
        navigation(target(e));
        openRightSideBar();
        toRouterManageCh('editChannel');
    };

    const CreateChannel = () => {
        if (setCreateChannelData.name?.length > 1) {
            createChannel(e => callbackHandle(e));
        } else {
            message.warning('Please enter group name');
        }
    };

    return (
        <div className={styles.container}>
            <Header text={t('createGroup')} leftIcon={'arrowLeft'} onLeftIconPress={() => closeModal('left')} />
            <div className={styles.contentBox}>
                <div className={styles.contentTop}>
                    <AvatarUpload
                        color={setCreateChannelData.color || 'linear-gradient(#ddd, #666)'}
                        upload={true}
                        imageUrl={createAvatar}
                        onChange={e => handleFileChange(e)}
                    />
                    <NewInput
                        onChange={e => setCreateChannelState('name', e)}
                        placeholder="Group Name"
                        margin="15px 0 0 0"
                        fontSize="15px"
                        value={setCreateChannelData.name}
                    />
                </div>
                <div className={styles.contentBottom}>
                    <div className={styles.descriptionBox}>
                        <textarea
                            className={styles.description}
                            rows={6}
                            placeholder="Enter description"
                            value={setCreateChannelData.description}
                            onChange={e => setCreateChannelState('description', e.target.value)}
                        />
                    </div>
                    <div className={styles.settings}>
                        <MenuItem
                            icon={<MdGroup size={24} />}
                            title={`Group type: ${setCreateChannelData.isPrivate ? 'Private' : 'Public'}`}
                            right={<Switch onChange={isPrivateGroup} />}
                        />
                        {setCreateChannelData.isPrivate && (
                            <NewInput
                                onChange={e => setCreateChannelState('password', e)}
                                placeholder="Password"
                                margin="0 15px"
                                width="93%"
                                fontSize="15px"
                                value={setCreateChannelData.password}
                            />
                        )}
                        <MenuItem
                            icon={<IoColorFilter size={24} />}
                            title={`Color`}
                            right={
                                <input
                                    value={setCreateChannelData.color}
                                    placeholder="Color"
                                    type="color"
                                    className={styles.colorUser}
                                    onChange={e => setCreateChannelState('color', e.target.value)}
                                />
                            }
                        />
                        <MenuItem
                            icon={<IoMdFunnel size={24} />}
                            title="Default relevance"
                            right={setCreateChannelData.defaultRelevance || '0'}
                            onClick={() => toglevisible('relevenceSliderCreate')}
                        />
                        <div
                            className={styles.sliderBox}
                            style={{
                                display: visible.relevenceSliderCreate ? 'block' : 'none',
                            }}
                        >
                            <Slider
                                className={styles.slider}
                                value={setCreateChannelData.defaultRelevance}
                                onChange={e => setCreateChannelState('defaultRelevance', e)}
                            />
                        </div>
                        <MenuItem
                            icon={<MdGroup size={24} />}
                            title="Add participiants"
                            onClick={() => toRouter('collectUsers')}
                        />
                        <MenuItem
                            icon={<MdGroup size={24} />}
                            title={`Group ${setCreateChannelData.isPremium ? 'Premium' : 'Normal'}`}
                            right={<Switch checked={setCreateChannelData.isPremium} onChange={isPremiumGroup} />}
                        />
                        {setCreateChannelData.isPremium && (
                            <>
                                {/* minRelevanceOfText */}
                                <MenuItem
                                    icon={<PiSlidersHorizontalBold size={24} />}
                                    title="Minimum relevance to text"
                                    right={setCreateChannelData.minRelevanceOfText || '0'}
                                    onClick={() => toglevisible('minRelevanceOfText')}
                                />
                                <div
                                    className={styles.sliderBox}
                                    style={{
                                        display: visible.minRelevanceOfText ? 'block' : 'none',
                                    }}
                                >
                                    <Slider
                                        value={setCreateChannelData.minRelevanceOfText}
                                        className={styles.slider}
                                        onChange={e => setCreateChannelState('minRelevanceOfText', e)}
                                    />
                                </div>
                                {/* qualityOfImage */}
                                <MenuItem
                                    icon={<PiSlidersHorizontalBold size={24} />}
                                    title="Increase resolution of images"
                                    right={setCreateChannelData.qualityOfImage || '0'}
                                    onClick={() => toglevisible('qualityOfImage')}
                                />
                                <div
                                    className={styles.sliderBox}
                                    style={{
                                        display: visible.qualityOfImage ? 'block' : 'none',
                                    }}
                                >
                                    <Slider
                                        className={styles.slider}
                                        value={setCreateChannelData.qualityOfImage}
                                        onChange={e => setCreateChannelState('qualityOfImage', e)}
                                    />
                                </div>
                                <MenuItem
                                    icon={<HiClipboardDocumentCheck size={24} />}
                                    title="Private group policy"
                                    onClick={() => toRouter('groupPolicy')}
                                />
                                <MenuItem
                                    icon={<FaLayerGroup size={24} />}
                                    title="Brand group chat"
                                    onClick={() => toRouter('brandGroupChat')}
                                />
                            </>
                        )}
                    </div>
                    <div className={styles.buttonSubmit}>
                        <ButtonComponent
                            width="100%"
                            margin="10px 0 0 0"
                            text="Create new group"
                            clickMe={CreateChannel}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(CreateChannel);
