import React, { useCallback, useEffect } from 'react';
import Header from '../../../components/Header/Header';
import useRootStore from '../../../hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import RowItemView from '../../../components/RowItem';
import { TMP_URL } from '../../../env';
import MessageBox from '../../../components/MessageBox/MessageBox';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import ButtonView from '../../../components/Button';
import styles from './JoinRequests.module.css';

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2,
        },
    },
};

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    },
};

const JoinRequests = () => {
    const { t } = useTranslation();
    const { joinRequestsData, acceptJoinRequest, rejectJoinRequest, hashId, channelData, getJoinRequests } =
        useRootStore().channelStore;
    const { closeModal } = useRootStore().routerStore;

    useEffect(() => {
        getJoinRequests(hashId);
    }, []);

    const acceptUserHandle = (uderId: number) => {
        acceptJoinRequest(hashId, uderId);
    };

    const rejectUserHandle = (userId: number) => {
        rejectJoinRequest(hashId, userId);
    };

    const renderData = useCallback(() => {
        return joinRequestsData.length > 0 ? (
            joinRequestsData.map((e, index) => {
                return (
                    <motion.div variants={item} key={index} id="map-dev">
                        <RowItemView
                            key={index}
                            loading={false}
                            color={e.color ? e.color : 'linear-gradient(#ddd, #666)'}
                            imageUrl={e.avatar ? `${TMP_URL}/${e.avatar}` : ''}
                            text={e.username.length > 10 ? e.username.slice(0, 10) + '...' : e.username}
                            rightItem={
                                <div className={styles.btnBox}>
                                    <ButtonView
                                        title="Accept"
                                        rounded
                                        color="lightGreen"
                                        fontSize="12px"
                                        onClickbutton={() => acceptUserHandle(e.id)}
                                    />
                                    <ButtonView
                                        title="Reject"
                                        rounded
                                        color="danger"
                                        fontSize="12px"
                                        onClickbutton={() => rejectUserHandle(e.id)}
                                    />
                                </div>
                            }
                        />
                    </motion.div>
                );
            })
        ) : (
            <MessageBox title={`${t('No available requests')}`} />
        );
    }, [joinRequestsData]);

    return (
        <div>
            <Header
                leftIcon="arrowLeft"
                text={'Join Requests'}
                onLeftIconPress={() => closeModal('right')}
                color={channelData.mainColor}
                isPremium={channelData.isPremium}
                colorPText={channelData.colorHighlight}
            />
            <div>
                <motion.div variants={container} initial="hidden" animate="visible">
                    {renderData()}
                </motion.div>
            </div>
        </div>
    );
};

export default observer(JoinRequests);
